<script>
export default {
  name: "AppConfirmModal"
}
</script>

<script setup>
import { AppFlex } from "@/components/layouts"
import { AppButton, AppImage, AppModal, AppText } from "@/components/ui"
import { tc } from "@/lib/services"

const props = defineProps({
  title: String,
  message: String,
  visible: Boolean,
  cancelText: {
    type: String,
    default: null
  },
  acceptText: {
    type: String,
    default: null
  },
  showAccept: {
    type: Boolean,
    default: true
  },
  image: {
    type: String,
    default: null
  },
  subMessage: {
    type: String,
    default: null
  },
  buttonSize: {
    type: String,
    default: "xl"
  },
  close: { type: Function, required: true },
  confirm: { type: Function, required: true }
})

defineEmits(["close", "confirm"])

const handleClose = () => {
  props.close()
}

const handleConfirm = () => {
  props.confirm()
}
</script>

<template>
  <AppModal
    size="md"
    :visible="visible"
    @handleClose="handleClose"
  >
    <template #content>
      <!-- Header -->
      <AppFlex
        justify="center"
        align="center"
        direction="col"
        class="p-3"
      >
        <!-- Image -->
        <AppImage
          v-if="image"
          :src="image"
          alt="gls-image"
          class="mb-3"
        />

        <!-- Title -->
        <AppText size="4" weight="bold">
          {{ title }}
        </AppText>

        <!-- Subtitle -->
        <AppText class="text-center mt-2">
          {{ message }}
        </AppText>

        <!-- Submessage -->
        <AppText v-if="subMessage" class="text-center my-4">
          {{ subMessage }}
        </AppText>

        <!-- Cancel Button -->
        <AppButton
          :block="true"
          class="mt-3"
          :size="buttonSize"
          :text="cancelText || tc('General.Cancel')"
          @on-clicked="handleClose"
        />

        <!-- Accept Button -->
        <AppButton
          v-if="showAccept"
          :block="true"
          class="mt-3"
          :size="buttonSize"
          variant="primary"
          :outline="true"
          :text="acceptText || tc('General.Accept')"
          @on-clicked="handleConfirm"
        />
      </AppFlex>
    </template>
  </AppModal>
</template>
