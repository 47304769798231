<script>
export default {
  name: "AppBreadcrumbs"
}
</script>

<script setup>
import { AppFlex } from "@/components/layouts"
import { AppLink, AppText, AppIcon } from "@/components/ui"
import { useWindowSize } from "@/composables"
import { ICON } from "@/lib/constants"

defineProps({ items: Array })

const { isMobile } = useWindowSize()
</script>

<template>
  <div>
    <AppFlex :gap="isMobile ? '2' : '4'" :wrap="true">
      <!-- Link -->
      <AppLink
        v-for="(item, index) in items"
        :key="index"
        :to="{ name: item?.route }"
        class="round-hover"
      >
        <AppFlex gap="2" align="center">
          <!-- Text -->
          <AppText type="primary" decoration="underline">
            {{ item?.text }}
          </AppText>

          <!-- Icon -->
          <AppIcon
            :name="ICON.CHEVRON_RIGHT"
            color="primary"
            size="18"
          />
        </AppFlex>
      </AppLink>
    </AppFlex>
  </div>
</template>
