<script>
export default {
  name: "AppTextArea",
  inheritAttrs: true
}
</script>

<script setup>
import { onMounted, ref, toRef, watch } from "vue"

import { useField } from "vee-validate"

import { AppText } from "../.."
import AppField from "../field/AppField.vue"

const props = defineProps({
  modelValue: String,
  name: {
    type: String,
    required: true
  },
  label: {
    type: String,
    default: null
  },
  placeholder: {
    type: String,
    default: null
  },
  alias: {
    type: String,
    required: true
  },
  focus: {
    type: Boolean,
    default: false
  },
  helpText: {
    type: String,
    default: null
  },
  disabled: {
    type: Boolean,
    default: false
  },
  rows: {
    type: Number,
    default: 5
  },
  autocomplete: {
    type: String,
    default: "on",
    validator: value => ["on", "off"].includes(value)
  }
})
defineEmits(["update:ModelValue"])

onMounted(() => {
  if (props.focus) {
    setTimeout(() => inputRef.value?.focus(), 500)
  }
})

const inputRef = ref(null)
const modelValue = toRef(props, "modelValue")

const { errorMessage, value: inputValue } = useField(() => props.name, undefined, {
  validateOnMount: false,
  validateOnValueUpdate: true,
  type: "default",
  initialValue: props.modelValue,
  valueProp: props.modelValue
})

watch(modelValue, newValue => {
  inputValue.value = newValue
})
</script>

<template>
  <AppField
    :alias="alias"
    :label="label"
    :help-text="helpText"
  >
    <!-- Text Area -->
    <textarea
      :id="alias"
      ref="inputRef"
      v-model="inputValue"
      class="form-control"
      :name="name"
      :disabled="disabled"
      :rows="rows"
      :autocomplete="autocomplete"
      :placeholder="placeholder || $t('General.WriteHere')"
      @change="$emit('update:ModelValue', $event.target.value)"
    />

    <!-- Error Text -->
    <AppText
      v-if="errorMessage"
      type="danger"
      tag="small"
      lh="sm"
    >
      {{ errorMessage }}
    </AppText>
  </AppField>
</template>

<style lang="scss" scoped>
textarea {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 0px 0px 1px rgba(103, 110, 118, 0.16);
  box-shadow: 0px 2px 5px 0px rgba(103, 110, 118, 0.08);
  border-radius: 8px;
  padding: 10px 14px 10px 14px !important;
  border-radius: 8px;
  min-width: 202.6px !important;
}
</style>