export const ROUTE_PATHS = {
  // Base
  EMPTY: "",
  HOME: "/",

  // Auth Routes
  AUTH: {
    BASE: "/auth",
    LOGIN: "login",
    FORGOT_PASSWORD: "forgot-password"
  },

  // ------------------------------------ Admin Entity -------------------
  ADMIN: {
    BASE: "/admin",

    // Routes
    ROUTES: {
      PANEL: "routes-panel",
      DETAILS: ":id/details"
    },

    // PDI's
    PDIS: {
      LIST: "pdis",
      CREATE: "create",
      EDIT: ":id/edit"
    },

    // Alerts
    ALERTS: "alerts",

    // Profile
    PROFILE: "profile",

    // Settings
    SETTINGS: "settings",

    // Users
    USERS: "users"
  },

  // -------------------------------------------- Providers Entity ---------------
  PROVIDERS: {
    BASE: "/providers",

    // Contracted Routes
    CONTRACTED_ROUTES: "contracted-routes",

    // Daily Routes
    DAILY_ROUTES: "daily-routes",

    // Contracted Routes
    PROFILE: "profile",

    // Retainers
    RETAINERS: "retainers",

    // Drivers
    DRIVERS: "drivers",

    // Vehicles
    VEHICLES: "vehicles",

    // Trailers
    TRAILERS: "trailers"
  },

  // Errors
  ERRORS: {
    FORBIDDEN: "/403",
    NOT_FOUND: {
      BASE: "/404",
      PATH: "/:pathMatch(.*)*"
    }
  }

}

Object.freeze(ROUTE_PATHS)