<script>
export default {
  name: "AppAlert"
}
</script>

<script setup>
import { AppIcon } from ".."

import { useAppAlert } from "./composables/useAppAlert"

import { AppFlex } from "@/components/layouts"

const props = defineProps({
  variant: {
    type: String,
    default: "secondary",
    validator: value => [
      "primary", "secondary", "success", "danger",
      "warning", "info", "light", "dark", "link",
      "silver", "sunshine", "blush", "white"
    ].includes(value)
  },
  text: String,
  showIcon: {
    type: Boolean,
    default: true
  },
  allowToClose: {
    type: Boolean,
    default: false
  },
  iconSize: {
    type: String,
    default: "28"
  },
  textSize: {
    type: String,
    default: "16"
  },
  uppercase: {
    type: Boolean,
    default: true
  },
  gap: {
    type: String,
    default: "3"
  },
  shape: {
    type: String,
    default: "rounded",
    validator: value => ["rounded", "rounded-100"].includes(value)
  },
  size: {
    type: String,
    default: "lg",
    validator: value => ["lg", "sm"].includes(value)
  }
})

const {
  // States
  alertClasses,
  icon,
  closed,

  // Function
  handleClose
} = useAppAlert(props)
</script>

<template>
  <AppFlex
    v-if="!closed"
    :class="alertClasses"
    role="alert"
    :direction="$slots?.default ? 'col' : null"
    gap="2"
  >
    <AppFlex justify="between" class="w-100">
      <!-- Content -->
      <AppFlex :gap="gap" align="center">
        <!-- Main Icon -->
        <div>
          <AppIcon
            v-if="icon"
            :color="'black'"
            :size="iconSize"
            :name="icon"
          />
        </div>

        <!-- Text -->
        <span
          :class="['mt-1 app-alert-text', { 'text-uppercase': uppercase }]"
          :style="`font-size: ${textSize}px`"
        >
          {{ text }}
        </span>
      </AppFlex>

      <!-- Close button -->
      <button
        v-if="allowToClose"
        class="btn-close"
        @click="handleClose"
      />
    </AppFlex>

    <!-- Children optional content -->
    <AppFlex v-if="$slots?.default" class="ms-5 mt-3 mb-2 ms-sm-unset">
      <slot />
    </AppFlex>
  </AppFlex>
</template>

<style lang="scss" scoped>
.app-alert-text {
  font-weight: 400;
  line-height: 18px;
}

.app-alert-small {
  max-height: 30px;
  width: fit-content;
}
</style>