import axios from "axios"

import { useCrypto } from "@/composables"
import { ENV, PAGE_URLS, UI } from "@/lib/constants"
import { tc } from "@/lib/services"
import { resetAllPiniaStores, useAuthStore } from "@/stores"

const install = app => {
  // Set the default base URL for axios
  axios.defaults.baseURL = ENV.API_URL

  // Destructure router and toast from app's global properties
  const { $router, $toast } = app.config.globalProperties

  // Access the authentication store
  const auth = useAuthStore()

  // Destructure decrypt and encryptKey from useCrypto composable
  const { decrypt, encryptKey } = useCrypto()

  // Encrypt the token key to retrieve from localStorage
  const encryptedTokenKey = encryptKey(UI.LOCALSTORAGE.TOKEN_KEY)
  const encryptedUserkey = encryptKey(UI.LOCALSTORAGE.CURRENT_USER_KEY)

  // Axios request interceptor
  axios.interceptors.request.use(
    config => {
      const encryptedToken = localStorage.getItem(encryptedTokenKey)

      // If encrypted token exists, decrypt and set it in the Authorization header
      if (encryptedToken) {
        const realToken = decrypt(encryptedToken)
        config.headers.Authorization = realToken
      }

      return config
    },
    error => {
      return Promise.reject(error)
    }
  )

  // Axios response interceptor
  axios.interceptors.response.use(
    response => response,
    error => {
      const status = error.response ? error.response.status : null

      // Object mapping for status code redirections
      const statusRedirects = {
        401: PAGE_URLS.AUTH.LOGIN,
        403: PAGE_URLS.ERROR.FORBIDDEN
      }

      // Check if status code exists in the mapping, otherwise display a generic error toast
      const redirectRoute = statusRedirects[status]
      if (redirectRoute) {
        // Remove token if status is 401 and redirect to new page
        if (status === 401) {
          auth.logout()
          localStorage.removeItem(encryptedTokenKey)
          localStorage.removeItem(encryptedUserkey)
          resetAllPiniaStores()
        }
        $router.push(redirectRoute)
      } else if (status === 500) {
        $toast({
          title: tc("General.Error"),
          message: tc("General.ErrorText"),
          type: "error"
        })
      }

      return Promise.reject(error)
    }
  )

  // Add axios instance to app's global properties
  app.config.globalProperties.$axios = axios
}

export default install