import * as yup from "yup"

import { tc } from "@/lib/services"

export default {
  // Form Data
  data: {
    Email: "",
    Password: "",
    Remember: false
  },

  // Form Validations
  schema: yup.object().shape({
    /* Email */
    Email: yup.string()
      .email(tc("Validations.InvalidEmail"))
      .required(tc("Validations.Required")),

    /* Password */
    Password: yup.string().required(tc("Validations.Required")),

    /* Remember */
    Remember: yup.boolean()
  })
}