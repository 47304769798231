<script>
export default {
  name: "AppPublicHeader"
}
</script>

<script setup>
import { AppDevelopmentBanner, AppLogo } from "@/components/common"
import { AppFlex } from "@/components/layouts"
</script>

<template>
  <div>
    <!-- Dev Banner -->
    <AppDevelopmentBanner :apply-margin="false" />

    <!-- Public Header -->
    <AppFlex
      justify="center"
      align="center"
      class="bg-primary py-4 sticky-top"
    >
      <AppLogo />
    </AppFlex>
  </div>
</template>
