<script>
export default {
  name: "AppTabs"
}
</script>

<script setup>
import { AppBadge, AppDivider } from ".."

defineProps({
  tabs: Array,
  activeKey: String
})

defineEmits(["updateTab"])
</script>

<template>
  <nav>
    <!-- Container -->
    <div
      id="nav-tab"
      class="nav nav-tabs gap-2 border-bottom-none"
      role="tablist"
    >
      <!-- Buttons -->
      <button
        v-for="(tab, index) in tabs"
        :key="index"
        :class="[
          'd-flex gap-3 pb-3 nav-link material-design',
          { active: tab?.key === activeKey },
        ]"
        type="button"
        @click="$emit('updateTab', tab?.key)"
      >
        <!-- Button Text -->
        <span :class="['tab-text', {'text-primary': tab?.key === activeKey}]">
          {{ tab?.text }}
        </span>

        <!-- Badge-->
        <AppBadge
          v-if="tab?.count"
          type="orange"
          :text="tab?.count"
          shape="circle"
          class="p-badge"
        />
      </button>
    </div>
    <AppDivider />
  </nav>
</template>

<style
  src="./styles/app-tabs.styles.scss"
  lang="scss"
  scoped
/>
