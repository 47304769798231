import { ref } from "vue"

// Composable to manage the AppTabs.vue component
export const useTabs = (defaultTab, tabList) => {
  // Ref data
  const currentTab = ref(defaultTab)
  const tabs = ref(tabList)

  // Update the current selected tab.
  const updateCurrentTab = value => {
    currentTab.value = value
  }

  return {
    // States
    currentTab,
    tabs,

    // Functions
    updateCurrentTab
  }
}
