<script>
export default {
  name: "AppDivider"
}
</script>

<script setup>
import { ref, computed } from "vue"

const { type, height, color, full } = defineProps({
  type: {
    type: String,
    default: "horizontal",
    validator: value => ["horizontal", "vertical"].includes(value)
  },
  height: {
    type: String,
    default: "80"
  },
  full: {
    type: Boolean,
    default: false
  },
  color: {
    type: String,
    default: "#115da1"
  }
})

const computedClasses = computed(() => {
  const classes = []

  classes.push(
    // Type
    ...(type === "horizontal" ? ["border-top"] : ["border-left"]),

    // Heigth
    ...(full ? ["h-100"]: [])
  )

  return classes
})

const computedStyles = ref({
  height: type === "vertical" ? full ? "" : `${height}px` : "",
  border: type === "vertical" ? `0.1rem solid ${color}` : ""
})
</script>

<template>
  <div
    :class="computedClasses"
    :style="computedStyles"
  />
</template>
