<script>
export default {
  name: "AppToast"
}
</script>

<script setup>
import { onMounted, onUnmounted } from "vue"

import { AppIcon, AppText } from ".."

import { useAppToast } from "./composables/useAppToast"

import { AppFlex } from "@/components/layouts"

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  message: {
    type: String,
    required: true
  },
  timeout: {
    type: Number,
    default: 3000,
    validator: val => val >= 0
  },
  close: {
    type: Function,
    default: () => {}
  },
  type: {
    type: String,
    default: "success",
    validator: val => ["success", "danger", "info"].includes(val)
  }
})

const {
  // States
  iconStyles,
  isClosing,
  toastStyle,
  toastType,
  progressStyle,

  // Functions
  handleBeforeClose,
  startProgress,
  toClearTimeout
} = useAppToast(props)

onMounted(() => {
  toClearTimeout()
  if (props.timeout > 0) {
    handleBeforeClose()
    startProgress()
  }
})

onUnmounted(() => toClearTimeout())
</script>

<template>
  <div
    :class="['toast fade-in', { 'slide-out-right-animation': isClosing }]"
    :style="toastStyle"
  >
    <AppFlex class="w-100" direction="col">
      <!-- Close btn -->
      <AppFlex justify="end">
        <button
          type="button"
          class="btn-close round-hover mt-1"
          @click="close"
        />
      </AppFlex>

      <AppFlex gap="4" class="app-toast-content">
        <!-- Icon -->
        <div :style="iconStyles" class="app-toast-icon-container">
          <AppIcon
            :name="toastType.icon"
            :color="type"
            class="app-toast-icon"
          />
        </div>

        <!-- Content -->
        <AppFlex direction="col" class="app-toast-texts">
          <!-- Title -->
          <AppText weight="bold" type="muted" size="6">
            {{ title }}
          </AppText>

          <!-- Message -->
          <AppText type="muted">
            {{ message }}
          </AppText>
        </AppFlex>
      </AppFlex>

      <!-- Progress -->
      <div class="progress">
        <div
          class="progress-bar"
          :style="progressStyle"
        />
      </div>
    </AppFlex>
  </div>
</template>

<style
  src="./styles/app-toast.styles.scss"
  lang="scss"
  scoped
/>
