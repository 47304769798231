import L from "leaflet"

import { usePlugins } from "@/composables"
import { tc } from "@/lib/services"
import "leaflet-draw"

class MapFiguresManager {
  constructor() {
    // Circle
    this.currentCircle = null
    this.drawnCircle = null

    // Polygon
    this.drawPolygonControl = null
    this.currentPolygon = null
  }

  /* ---------------------------------------- Circle Functions ----------------------------- */

  // Function to show a circle
  showCircle({ map, location, radius }) {
    if (!location) return
    const circle = L.circle(location, {
      radius,
      className: "app-map-circle"
    }).addTo(map)
    this.currentCircle = circle
  }

  // Function to remove current circle
  removeCurrentCircle() {
    if (!this.currentCircle) return
    this.currentCircle.removeFrom(this.currentCircle._map)
    this.currentCircle = null
  }

  /* ------------------------------------- Poligon functions --------------------------- */

  // Function to add draw polygon control
  addDrawPolygonControl(map) {
    this.drawPolygonControl = new L.Control.Draw({
      position: "bottomleft",
      draw: {
        polygon: true,
        circle: false,
        circlemarker: false,
        marker: false,
        polyline: false,
        rectangle: false
      }
    })

    this.editPolygonControlTexts()

    map.addControl(this.drawPolygonControl)
  }

  // Function to show Polygon
  showPolygon(map, polygon) {
    if (!polygon) return
    const newpolygon = L.polygon(polygon.coordinates).addTo(map)
    this.currentPolygon = { key: polygon?.key, coordinates: newpolygon }
  }

  // Function to validate a polygon
  isPolygonValid(existingPolygons, layer) {
    let polygonIsValid = true
    if (existingPolygons?.length === 0) return true

    existingPolygons.forEach(polygon => {
      if (polygon?.coordinates?.length > 0) {
        const predefinedLatLngs = polygon?.coordinates?.map(coord => L.latLng(coord[0], coord[1]))
        const predefinedPolygon = L.polygon(predefinedLatLngs)

        if (predefinedPolygon.getBounds().intersects(layer.getBounds())) {
          polygonIsValid = false
          return
        }
      }
    })
    return polygonIsValid
  }

  // Listener for new polygons drawn
  listenToDrawnPolygon(map, emit, existingPolygons) {
    const { $toast } = usePlugins()

    map.on(L.Draw.Event.CREATED, event => {
      const layer = event.layer
      if (layer instanceof L.Polygon) {

        if (!this.isPolygonValid(existingPolygons, layer)) {
          $toast({
            title: tc("General.Error"),
            message: tc("Map.RepeatedError"),
            type: "danger"
          })
          map.removeLayer(layer)
          return
        }

        // Emit new polygon to parent component
        const newPolygon = {
          key: this.currentPolygon?.key,
          coordinates: layer.getLatLngs()[0].map(latlng => [latlng.lat, latlng.lng])
        }
        emit("updatePolygon", newPolygon)

        // Remove current polygon
        this.removeCurrentPolygon(map)

        // Show new polygon
        map.addLayer(layer)
      }
    })
  }

  // Function to remove current areas
  removeCurrentPolygon(map) {
    if (!map) return
    this.currentPolygon = null
    map.eachLayer(layer => {
      if (layer instanceof L.Polygon) {
        map.removeLayer(layer)
      }
    })
  }

  // Function to show existing polygons
  showExistinPolygons(map, currentPolygon, polygons) {
    if (polygons?.length === 0) return

    polygons.forEach(polygon => {
      const conditionsAreTrue = [
        Boolean(currentPolygon),
        currentPolygon?.key !== polygon?.key,
        polygon?.coordinates?.length > 0
      ].every(condition => condition)

      if (conditionsAreTrue) {
        const latLngs = polygon.coordinates.map(coord => L.latLng(coord[0], coord[1]))
        const existingPolygon = L.polygon(latLngs, { opacity: 0.3 })
        existingPolygon.addTo(map)
      }
    })
  }

  // Function to edit the polygon control texts
  editPolygonControlTexts() {
    window.L.drawLocal.draw.toolbar.buttons.polygon = tc("Map.DrawPolygon")
    window.L.drawLocal.draw.handlers.polygon.tooltip.start = tc("Map.StartArea")
    window.L.drawLocal.draw.handlers.polygon.tooltip.cont = tc("Map.ContArea")
    window.L.drawLocal.draw.handlers.polygon.tooltip.end = tc("Map.ContArea")
    window.L.drawLocal.draw.toolbar.actions.text = tc("General.Cancel")
    window.L.drawLocal.draw.toolbar.actions.title = tc("General.Cancel")
    window.L.drawLocal.draw.toolbar.finish.text = tc("General.Finish")
    window.L.drawLocal.draw.toolbar.finish.title = tc("General.Finish")
    window.L.drawLocal.draw.toolbar.undo.text = tc("Map.DeleteLastPoint")
    window.L.drawLocal.draw.toolbar.undo.title = tc("Map.DeleteLastPoint")
  }
}

export const mapFiguresManager = new MapFiguresManager()
