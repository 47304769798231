import { ref, onMounted, onBeforeUnmount } from "vue"

// Custom composable function to track window size and determine device type
export const useWindowSize = () => {
  // Refs to store window width and device type flags
  const windowWidth = ref(window.innerWidth)
  const isMobile = ref(windowWidth.value <= 800)
  const isTablet = ref(windowWidth.value > 800 && windowWidth.value <= 1200)
  const isDesktop = ref(windowWidth.value > 1024)

  // Function to update window width and device type flags
  const updateWindowWidth = () => {
    windowWidth.value = window.innerWidth
    isMobile.value = window.innerWidth <= 800
    isTablet.value = window.innerWidth > 800 && window.innerWidth <= 1200
    isDesktop.value = window.innerWidth > 1200
  }

  // Event listener to update on window resize when component is mounted
  onMounted(() => {
    window.addEventListener("resize", updateWindowWidth)
  })

  // Remove event listener when component is unmounted to avoid memory leaks
  onBeforeUnmount(() => {
    window.removeEventListener("resize", updateWindowWidth)
  })

  // Return window width and device type flags for usage in components
  return {
    windowWidth,
    isMobile,
    isTablet,
    isDesktop
  }
}
