<script>
export default {
  name: "AppPageTitle"
}
</script>

<script setup>
import { AppFlex } from "@/components/layouts"
import { AppIcon, AppLink, AppText } from "@/components/ui"
import { ICON } from "@/lib/constants"

defineProps({
  text: {
    type: String,
    required: true
  },
  route: {
    type: String,
    default: null
  }
})
</script>

<template>
  <div>
    <AppFlex gap="3" align="center">
      <!-- Url -->
      <AppLink v-if="route" :to="{ name: route }">
        <AppIcon
          :name="ICON.ARROW_LEFT"
          color="primary"
          class="round-hover"
        />
      </AppLink>

      <!-- Text -->
      <AppText
        type="black"
        size="3"
        weight="bold"
        :class="{ 'mt-1': Boolean(route) }"
      >
        {{ text }}
      </AppText>
    </AppFlex>
  </div>
</template>
