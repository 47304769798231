<script>
export default {
  name: "AppHeaderLogoutButton"
}
</script>

<script setup>
import { useRouter } from "vue-router"

import { AppButton } from "@/components/ui"
import { useFetch, useLocalStorage, useWindowSize } from "@/composables"
import { ENDPOINTS, ICON, METHODS, ROUTE_NAMES, UI } from "@/lib/constants"
import { resetAllPiniaStores, useAuthStore, useUiStore } from "@/stores"

const ui = useUiStore()
const auth = useAuthStore()
const router = useRouter()
const { removeItem: removeUser } = useLocalStorage(UI.LOCALSTORAGE.CURRENT_USER_KEY)
const { removeItem: removeToken } = useLocalStorage(UI.LOCALSTORAGE.TOKEN_KEY)
const { isMobile } = useWindowSize()

const { submit } = useFetch({
  url: ENDPOINTS.AUTH.LOGOUT,
  method: METHODS.DELETE,
  useLoader: true
})

const handleLogout = () => {
  submit({}, () => {
    auth.logout()
    removeUser()
    removeToken()
    resetAllPiniaStores()
    router.push({ name: ROUTE_NAMES.AUTH.LOGIN })
  })
}
</script>

<template>
  <AppButton
    id="logout-btn"
    v-tooltip.right="$t('Menu.Logout')"
    :icon="ICON.LOGOUT"
    :outline="true"
    class="border-none"
    :text-color="isMobile ? 'white' : 'primary'"
    :icon-color="isMobile ? 'white' : 'primary'"
    :class="{ 'hide-tooltip': ui.sidebarIsOpen }"
    text-class="fade-in mt-1"
    :text="ui.sidebarIsOpen ? $t('Menu.Logout') : ''"
    @on-clicked="handleLogout"
  />
</template>
