import CryptoJS from "crypto-js"

export const useCrypto = () => {
  // Retrieve the secret key from environment variables
  const secretKey = process.env.VUE_APP_CRYPTO_SECRET_KEY

  // Encrypts the given data using AES encryption algorithm.
  const encrypt = data => {
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString()
    return encryptedData
  }

  // Decrypts the given encrypted data using AES decryption algorithm.
  const decrypt = encryptedData => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey)
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      return decryptedData
    } catch (error) {
      return null
    }
  }

  //  Encrypts the given key using SHA-256 hashing algorithm.
  const encryptKey = key => {
    const encryptedKey = CryptoJS.SHA256(key).toString()
    return encryptedKey
  }

  return {
    encrypt,
    decrypt,
    encryptKey
  }
}
