import { usePlugins } from "./usePlugins"

export const useAxios = () => {
  // Destructure the $axios instance from usePlugins hook
  const { $axios } = usePlugins()

  // Function to make an Axios request
  const request = ({ method, url, data = null, headers }) => {
    return $axios[method](url, data, headers)
  }

  return { request }
}
