
<script>
export default {
  name: "AppHeaderLinkItem"
}
</script>

<script setup>
import { computed } from "vue"

import { useRoute } from "vue-router"

import { AppFlex } from "@/components/layouts"
import { AppText, AppIcon, AppLink } from "@/components/ui"
import { useWindowSize } from "@/composables"
import { useUiStore } from "@/stores"

const props = defineProps({ link: Object })
defineEmits(["on-clicked"])

const ui = useUiStore()
const route = useRoute()
const { isMobile } = useWindowSize()

const isActive = computed(() => route.name?.includes(props?.link?.name))
</script>

<template>
  <AppLink
    :to="{ name: link?.name }"
    class="link"
    :class="{
      'hover-primary': ui.sidebarIsOpen,
      'focus-primary': isActive && ui.sidebarIsOpen && !isMobile
    }"
    @click="$emit('on-clicked')"
  >
    <AppFlex
      gap="4"
      align="center"
      class="container"
    >
      <!-- Icon -->

      <AppIcon
        :name="link?.icon"
        variant="solid"
        :color="isMobile ? 'white' : 'primary'"
        :class="{
          'hover-primary': !ui.sidebarIsOpen,
          'focus-primary': isActive && !ui.sidebarIsOpen && !isMobile
        }"
      />

      <!-- Text -->
      <AppText
        v-if="ui.sidebarIsOpen"
        :type="isMobile ? 'white' : 'primary'"
        :weight="isActive ? 'bold' : 'normal'"
        class="mt-1 text fade-in"
      >
        {{ link?.text }}
      </AppText>
    </AppFlex>
  </AppLink>
</template>

<style lang="scss" scoped>
.link {
  padding: 10px 0 0 5px;

  .container {
    padding: 0 0 10px 0;

    .text {
      position: fixed;
      left: 80px;
    }
  }
}
</style>