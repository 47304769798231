<script>
export default {
  name: "AppDateRangeInput",
  inheritAttrs: true
}
</script>

<script setup>
import { ref, watch } from "vue"

import { useAppDateRangeInput } from "./composables/useAppDateRangeInput"

import { AppFlex } from "@/components/layouts"

const props = defineProps({
  startModelValue: String,
  endModelValue: String,
  name: {
    type: String,
    required: true
  },
  label: {
    type: String,
    default: null
  },
  alias: {
    type: String,
    required: true
  }
})
const emit = defineEmits(["update"])

const startCurrentValue = ref(props.startModelValue || null)
const endCurrentValue = ref(props.endModelValue || null)

const params = {
  startRef: startCurrentValue,
  endRef: endCurrentValue,
  emit
}

const {
  currentDateText,
  handleStartDateChange,
  handleEndDateChange
} = useAppDateRangeInput(params)

watch(() => props.startModelValue, newValue => {
  startCurrentValue.value = newValue
})
watch(() => props.endModelValue, newValue => {
  endCurrentValue.value = newValue
})
</script>

<template>
  <div class="date-range-picker">
    <!-- Label -->
    <AppFlex justify="between" align="center">
      <label v-if="label" :for="alias" class="form-label">
        {{ label }}
      </label>

      <small class="text-muted me-2 sm-hidden help-text">
        {{ currentDateText }}
      </small>
    </AppFlex>


    <div class="input-group app-flex-sm-column">
      <!-- Start Date Input -->
      <input
        :value="startCurrentValue"
        :name="`${name}-start`"
        type="date"
        class="form-control w-sm-100 br-sm-8"
        @input="handleStartDateChange"
      >

      <!-- End Date Input -->
      <input
        :value="endCurrentValue"
        :name="`${name}-end`"
        type="date"
        class="form-control w-sm-100 br-sm-8"
        @input="handleEndDateChange"
      >
    </div>
  </div>
</template>

<style
  scoped
  lang="scss"
  src="./styles/app-date-range.styles.scss"
/>