import { ROUTE_NAMES, ROUTE_PATHS } from "@/lib/constants"

export default [
  // Profile
  {
    path: ROUTE_PATHS.PROVIDERS.PROFILE,
    name: ROUTE_NAMES.PROVIDERS.PROFILE,
    component: () => import(/* webpackChunkName: "provider-profile" */ "./modules/profile/Index.vue")
  },

  // Contracted Routes
  {
    path: ROUTE_PATHS.PROVIDERS.CONTRACTED_ROUTES,
    name: ROUTE_NAMES.PROVIDERS.CONTRACTED_ROUTES,
    component: () => import(/* webpackChunkName: "provider-contracted-routes" */ "./modules/contracted-routes/Index.vue")
  },

  // Daily Routes
  {
    path: ROUTE_PATHS.PROVIDERS.DAILY_ROUTES,
    name: ROUTE_NAMES.PROVIDERS.DAILY_ROUTES,
    component: () => import(/* webpackChunkName: "provider-daily-routes" */ "./modules/daily-routes/Index.vue")
  },

  // Retainers
  {
    path: ROUTE_PATHS.PROVIDERS.RETAINERS,
    name: ROUTE_NAMES.PROVIDERS.RETAINERS,
    component: () => import(/* webpackChunkName: "provider-retainers" */ "./modules/retainers/Index.vue")
  },

  // Drivers
  {
    path: ROUTE_PATHS.PROVIDERS.DRIVERS,
    name: ROUTE_NAMES.PROVIDERS.DRIVERS,
    component: () => import(/* webpackChunkName: "provider-drivers" */ "./modules/drivers/Index.vue")
  },

  // Vehicles
  {
    path: ROUTE_PATHS.PROVIDERS.VEHICLES,
    name: ROUTE_NAMES.PROVIDERS.VEHICLES,
    component: () => import(/* webpackChunkName: "provider-vehicles" */ "./modules/vehicles/Index.vue")
  },

  // Trailers
  {
    path: ROUTE_PATHS.PROVIDERS.TRAILERS,
    name: ROUTE_NAMES.PROVIDERS.TRAILERS,
    component: () => import(/* webpackChunkName: "provider-vehicles" */ "./modules/trailers/Index.vue")
  }
]