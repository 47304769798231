<script>
export default {
  name: "AppLabels"
}
</script>

<script setup>
import { AppFlex } from "@/components/layouts"
import { AppText } from "@/components/ui"

defineProps({
  title: String,
  subtitle: [ String, Number ],
  textType: {
    type: String,
    default: "black"
  },
  titleWeight: {
    type: String,
    default: "bold"
  },
  subtitleWeight: {
    type: String,
    default: "normal"
  },
  gap: {
    type: String,
    default: null
  },
  direction: {
    type: String,
    default: "col"
  },
  align: {
    type: String,
    default: null
  }
})
</script>

<template>
  <AppFlex :direction="direction" :gap="gap" :align="align">
    <!-- Title -->
    <AppText
      size="6"
      :weight="titleWeight"
      :type="textType"
    >
      {{ title }}
    </AppText>

    <!-- Subtitle -->
    <AppText
      :type="textType"
      :weight="subtitleWeight"
      tag="small"
    >
      {{ subtitle }}
    </AppText>
  </AppFlex>
</template>
