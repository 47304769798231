import dates from "./dates.service"

class FilesService {
  // Method to download CSV: dump raw data into a file at Downloads folder
  downloadCSV(data, fileName) {
    // Create a Blob from the data with the appropriate MIME type
    const blob = new Blob([data], { type: "text/csv;charset=utf-8;" })

    // Create a temporary anchor element
    const link = document.createElement("a")
    if (link.download === undefined) return

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob)
    link.setAttribute("href", url)

    // Generate the file name
    const name = `${fileName}_${dates.todayString()}.csv`

    link.setAttribute("download", name)
    link.style.visibility = "hidden"

    // Trigger the download
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  // Method to download PDF: dump raw data into a file at Downloads folder
  downloadPDF(data, fileName) {
    // Create a Blob from the data with the appropriate MIME type
    const blob = new Blob([data], { type: "application/pdf" })

    // Create a temporary anchor element
    const link = document.createElement("a")
    if (link.download === undefined) return

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob)
    link.setAttribute("href", url)

    // Generate the file name
    const name = `${fileName}_${dates.todayString()}.pdf`

    link.setAttribute("download", name)
    link.style.visibility = "hidden"

    // Trigger the download
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}

const file = new FilesService()
export default file
