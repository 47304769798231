<script setup>
import { computed } from "vue"

import { useRoute } from "vue-router"

import { AppScrollUp } from "@/components/common"
import { AppHeader, AppPublicHeader, AppRouterView } from "@/components/layouts"
import { useAuthStore } from "@/stores"

const auth = useAuthStore()
const route = useRoute()
const isAuthRoute = computed(() => route.path?.includes("auth"))
</script>

<template>
  <!-- Panel -->
  <template v-if="auth.isAuthenticated && !isAuthRoute">
    <AppHeader>
      <AppRouterView />
    </AppHeader>
  </template>

  <!-- Public -->
  <template v-else>
    <AppPublicHeader />
    <AppRouterView />
  </template>

  <!-- Scroll UP Button -->
  <AppScrollUp />
</template>
