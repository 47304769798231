import { createI18n } from "vue-i18n"

import es from "../assets/locales/es/common.json"

const DEFAULT_LANGUAGE_CODE = "es"

const APPLICATION_LANGUAGES = { es }

const messages = Object.assign(APPLICATION_LANGUAGES)

export const i18n = createI18n({
  legacy: false, // Required for composition api
  locale: DEFAULT_LANGUAGE_CODE,
  fallbackLocale: DEFAULT_LANGUAGE_CODE,
  messages
})
