<script>
export default {
  name: "AppField"
}
</script>

<script setup>
import { AppIcon, AppText } from "../.."

import { AppFlex } from "@/components/layouts"
import { ICON } from "@/lib/constants"

defineProps({
  label: {
    type: String,
    default: null
  },
  alias: {
    type: String,
    required: true
  },
  helpText: {
    type: String,
    default: null
  }
})
</script>

<template>
  <div>
    <!-- Label -->
    <slot name="label">
      <AppText
        v-if="label"
        tag="label"
        :for="alias"
        class="form-label"
      >
        {{ label }}
      </AppText>
    </slot>

    <!-- Input here -->
    <slot />

    <!-- Helper Content -->
    <AppFlex v-if="helpText" gap="2" align="center">
      <!-- Icon -->
      <AppIcon
        size="14"
        color="black"
        :name="ICON.INFORMATION_CIRCLE"
      />

      <!-- Help Text -->
      <AppText tag="span" type="muted" class="form-text">
        {{ helpText }}
      </AppText>
    </AppFlex>
  </div>
</template>
