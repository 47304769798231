<script>
export default {
  name: "AppSkeleton"
}
</script>

<script setup>
import { AppFlex } from "@/components/layouts"

const classes = ["", "w-70", "w-80", "w-95", "w-70", "w-80", "w-95"]
</script>

<template>
  <AppFlex class="skeleton" direction="col" gap="3">
    <div
      v-for="(itemClass, index) in classes"
      :key="index"
      class="skeleton-item animate"
      :class="itemClass"
    />
  </AppFlex>
</template>

<style
  src="./styles/app-skeleton.styles.scss"
  lang="scss"
  scoped
/>