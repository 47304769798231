import { ref } from "vue"

export const useHtmlDocument = () => {
  const bodyElement = ref(document?.body)

  const setOverFlow = value => {
    bodyElement.value.style.overflow = value
  }

  return {
    bodyElement,
    setOverFlow
  }
}
