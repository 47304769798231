<script>
export default {
  name: "AppFlex"
}
</script>

<script setup>
import { computed } from "vue"

const props = defineProps({
  gap: {
    type: [String, Number],
    default: null
  },
  justify: {
    type: String,
    default: null,
    validator: value => ["start", "end", "center", "between", "around"].includes(value)
  },
  align: {
    type: String,
    default: null,
    validator: value =>
      ["start", "end", "center", "baseline", "stretch"].includes(value)
  },
  direction: {
    type: String,
    default: "row",
    validator: value =>
      ["row", "col", "row-rev", "col-rev"].includes(value)
  },
  wrap: {
    type: Boolean,
    default: false
  },
  xsdir: {
    type: String,
    default: null,
    validator: value => ["col", "row"].includes(value)
  }
})

const computedClasses = computed(() => {
  const classes = ["d-flex"]

  const directionClass = {
    "row": "flex-row",
    "col": "flex-column",
    "row-rev": "flex-row-reverse",
    "col-rev": "flex-column-reverse"
  }[props.direction]

  classes.push(
    // Gap
    ...(props.gap ? [`gap-${props.gap}`] : []),

    // Direction
    ...([directionClass]),

    // Justify
    ...(props.justify ? [`justify-content-${props.justify}`] : []),

    // Align
    ...(props.align ? [`align-items-${props.align}`] : []),

    // Wrap
    ...(props.wrap ? ["flex-wrap"] : []),

    // Place column direction on mobile view
    ...(props.xsdir === "col" ? ["app-flex-sm-column"] : [])
  )

  return classes
})
</script>

<template>
  <div :class="computedClasses">
    <slot />
  </div>
</template>
