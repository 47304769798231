<script>
export default {
  name: "AppDateInput",
  inheritAttrs: true
}
</script>

<script setup>
import { onMounted, ref, watch } from "vue"

import { AppIcon } from "../.."
import AppField from "../field/AppField.vue"

import { ICON } from "@/lib/constants"

const props = defineProps({
  modelValue: String,
  name: {
    type: String,
    required: true
  },
  type: {
    type: String,
    default: "date",
    validator: value => ["date", "datetime-local", "time", "month", "week"].includes(value)
  },
  label: {
    type: String,
    default: null
  },
  alias: {
    type: String,
    required: true
  },
  placeholder: {
    type: String,
    default: null
  },
  focus: {
    type: Boolean,
    default: false
  },
  autocomplete: {
    type: String,
    default: "on",
    validator: value => ["on", "off"].includes(value)
  }
})
defineEmits(["update:ModelValue"])

const inputRef = ref(null)
const currentValue = ref(props.modelValue || null)

onMounted(() => {
  if (props.focus) {
    setTimeout(() => inputRef.value?.focus(), 500)
  }
})

watch(() => props.modelValue, newValue => {
  currentValue.value = newValue
})
</script>

<template>
  <AppField
    :alias="alias"
    :label="label"
  >
    <div class="input-group">
      <!-- Icon -->
      <span class="input-group-text">
        <AppIcon
          :name="ICON.CALENDAR"
          variant="solid"
          color="grey-500"
          size="20"
        />
      </span>

      <!-- Date Input -->
      <input
        :id="alias"
        ref="inputRef"
        v-model="currentValue"
        :name="name"
        :autocomplete="autocomplete"
        :placeholder="placeholder || $t('General.Date')"
        :type="type"
        :class="'form-control input-border-left-none'"
        @change="$emit('update:ModelValue', $event.target.value)"
      >
    </div>
  </AppField>
</template>

<style
  src="./styles/app-date-input.styles.scss"
  lang="scss"
  scoped
/>