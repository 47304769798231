<script>
export default {
  name: "AppTimeline"
}
</script>

<script setup>

defineProps({
  steps: {
    type: Number,
    required: true
  }
})
</script>

<template>
  <!-- TimeLine -->
  <ol class="timeline">
    <!-- Step -->
    <li
      v-for="step in steps"
      :key="step"
      class="timeline-item"
    >
      <!-- Bubble -->
      <span class="timeline-bubble" />

      <!-- Content -->
      <div class="w-100">
        <slot :name="`step-${step - 1}`" />
      </div>
    </li>
  </ol>
</template>

<style
  src="./styles/app-timeline.styles.scss"
  lang="scss"
  scoped
/>