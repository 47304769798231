<script>
export default {
  name: "AppHeaderMobileSidebar"
}
</script>

<script setup>
import { onMounted, onBeforeUnmount } from "vue"

import { AppHeaderLinkItem, AppHeaderLogoutButton, UserProfileHeader } from "../.."
import { useSidebarLinkList } from "../../../composables/useSidebarLinkList"

import { AppFlex } from "@/components/layouts"
import { useHtmlDocument } from "@/composables"
import { useUiStore } from "@/stores"

const { bodyElement } = useHtmlDocument()
const ui = useUiStore()
const { linkItems } = useSidebarLinkList()

onMounted(() => {
  bodyElement.value.addEventListener("click", handleBodyClick)
})

onBeforeUnmount(() => {
  bodyElement.value.removeEventListener("click", handleBodyClick)
})

const handleBodyClick = event => {
  const sidebarElement = document.querySelector(".sidebar")
  const headerElement = document.querySelector(".app-header")
  if (!sidebarElement?.contains(event.target) && !headerElement?.contains(event.target)) {
    ui.toogleSidebar()
  }
}
</script>

<template>
  <div class="sidebar-wrapper">
    <div class="sidebar mb-5 fade-in-left-animation">
      <UserProfileHeader />

      <!-- Logout Button -->
      <AppHeaderLogoutButton class="logout-btn" />

      <!-- Link Items -->
      <AppFlex
        gap="3"
        direction="col"
        class="mt-5"
      >
        <AppHeaderLinkItem
          v-for="(link, index) in linkItems"
          :key="index"
          :link="link"
          @on-clicked="ui.toogleSidebar"
        />
      </AppFlex>
    </div>
  </div>
</template>

<style
  src="./styles/app-header-mobile-sidebar.styles.scss"
  lang="scss"
  scoped
/>