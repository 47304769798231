import { tc } from "@/lib/services"

export const useAppTable = () => {
  // Function to get the text of a row in a specific column
  const getRowText = (row, column) => {
    const keyValue = row?.[column?.key]

    if (column?.render) {
      return column?.render(keyValue)
    }

    if (column?.variant === "boolean") {
      // If the key value is truthy, return "Yes"; otherwise, return "No"
      return keyValue ? tc("General.Yes") : tc("General.No")
    }

    return keyValue
  }

  // Function to get the second text of a row in a specific column
  const getSecondRowText = (row, column) => {
    const keyValue = row?.[column?.second_key]
    return keyValue
  }

  // Function to check if column action is visible
  const columnActionIsVisible = (row, column) => {
    if (column?.render && column?.variant === "action") {
      return column?.render(row)
    } else {
      return column?.variant === "action"
    }
  }

  return {

    // Functions
    columnActionIsVisible,
    getRowText,
    getSecondRowText
  }
}