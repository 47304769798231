const getPositionClass = modifiers => {
  if (modifiers?.top) {
    return "top"
  } else if (modifiers?.bottom) {
    return "bottom"
  } else if (modifiers?.left) {
    return "left"
  } else if (modifiers?.right) {
    return "right"
  }

  return "right"
}

export const tooltipDirective = (el, binding) => {
  const text = binding.value?.text || binding?.value
  if (!text) return
  el.setAttribute("data-tooltip", text)
  el.classList.add("with-tooltip")

  const position = binding?.value?.position || getPositionClass(binding?.modifiers)
  el.classList.add(`tooltip--${position}`)
}
