import { computed, ref } from "vue"

import { tc } from "@/lib/services"

export const useAppDropdown = (props, emit, selectedItem) => {
  const { displayProperty, valueProperty, options } = props

  const dropDownIsVisible = ref(false)

  const toogleDropdown = () => {
    dropDownIsVisible.value = !dropDownIsVisible.value
  }

  // Computed property that returns the current dropdown text based on the selected item
  const currentDropDownText = computed(() => {
    const mainText = tc("General.OrderBy")
    // Find the selected item in the options and return its corresponding text
    const selectedItemText = options?.find(option => option?.[valueProperty] === selectedItem.value)?.[displayProperty]

    return `${mainText}: ${selectedItemText}`
  })

  // Function executed when an item in the dropdown is clicked
  const onItemClicked = value => {
    // Update the value of the selected item
    selectedItem.value = value
    // Emit an event to update the model with the selected value
    emit("updateModelValue", value)
  }

  return {
    // States
    currentDropDownText,
    dropDownIsVisible,

    // Functions
    onItemClicked,
    toogleDropdown
  }
}