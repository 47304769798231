<script>
export default {
  name: "AppLinkButton"
}
</script>

<script setup>
import { computed } from "vue"

import { AppIcon, AppLink, AppText } from ".."

import { AppFlex } from "@/components/layouts"

const props = defineProps({
  to: {
    type: [String, Object],
    required: true
  },
  size: {
    type: String,
    default: "lg",
    validator: value => ["xl", "lg", "sm"].includes(value)
  },
  text: {
    type: String,
    default: null
  },
  variant: {
    type: String,
    default: "warning",
    validator: value => [
      "primary", "secondary", "success", "danger",
      "warning", "info", "light", "dark", "link"
    ].includes(value)
  },
  block: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  outline: {
    type: Boolean,
    default: false
  },
  icon: {
    type: String,
    default: null
  },
  centered: {
    type: Boolean,
    default: true
  },
  loading: {
    type: Boolean,
    default: false
  },
  uppercase: {
    type: Boolean,
    default: false
  },
  iconColor: {
    type: String,
    default: "primary"
  },
  textColor: {
    type: String,
    default: "primary"
  },
  textSize: {
    type: String,
    default: "6"
  },
  reverse: {
    type: Boolean,
    default: false
  },
  textClass: {
    type: String,
    default: null
  }
})

defineEmits(["on-clicked"])

const contentClasses = computed(() => {
  const classes = ["btn round-hover px-3"]

  classes.push(
    // Size
    ...(props.size ? [`btn-${props.size}`] : []),

    // Variant
    ...(props.variant && !props?.outline ? [`btn-${props.variant}`] : []),

    // Full With
    ...(props.block ? ["w-100"] : []),

    // Outline
    ...(props.outline ? [`btn-outline-${props.variant}`, "no-hover"] : []),

    // Uppercase
    ...(props.uppercase ? ["text-uppercase"] : []),
  )

  return classes
})

const linkClasses = computed(() => {
  const classes = []

  classes.push(
    // Full With
    ...(props.block ? ["w-100"] : []),
  )
  return classes.join(" ")
})
</script>

<template>
  <AppLink
    :to="to"
    :link-classes="linkClasses"
    variant="router"
  >
    <AppFlex
      gap="2"
      :justify="centered ? 'center' : 'start'"
      align="center"
      :direction="reverse ? 'row-rev' : 'row'"
      :class="contentClasses"
    >
      <!-- Icon -->
      <AppIcon
        v-if="icon"
        :name="icon"
        size="24"
        :color="iconColor"
      />

      <!-- Content -->
      <slot>
        <AppText
          v-if="text"
          :type="textColor"
          :size="textSize"
          :class="textClass"
        >
          {{ text }}
        </AppText>
      </slot>
    </AppFlex>
  </AppLink>
</template>
