<script>
export default {
  name: "AppForm",
  inheritAttrs: true
}
</script>

<script setup>
import { Form } from "vee-validate"

import { AppFlex } from ".."

import { AppButton } from "@/components/ui"

const props = defineProps({
  schema: Object,
  text: {
    type: String,
    default: null
  },
  initialValues: {
    type: Object,
    default: {}
  },
  footerIsVisible: {
    type: Boolean,
    default: true
  },
  acceptText: {
    type: String,
    default: null
  },
  loading: {
    type: Boolean,
    default: false
  },
  footerType: {
    type: String,
    default: "normal",
    validator: value => ["normal", "modal"].includes(value)
  }
})

defineEmits(["submit", "handleCancel"])
</script>

<template>
  <Form
    v-slot="{ values, resetField, handleReset, setFieldValue, errors }"
    as="form"
    :initial-values="initialValues"
    :validation-schema="props.schema"
    :keep-values="true"
    @submit="$emit('submit', $event)"
  >
    <slot v-bind="{ values, resetField, handleReset, setFieldValue, errors }" />

    <slot name="footer">
      <div v-if="footerIsVisible && footerType === 'normal'">
        <AppButton
          id="logout-btn"
          type="submit"
          class="mt-4"
          :loading="loading"
          :block="true"
          :text="text || $t('General.Save')"
        />

        <!-- Content under submit button -->
        <slot name="extra-footer" />
      </div>
      <AppFlex
        v-else-if="footerIsVisible && footerType === 'modal'"
        justify="start"
        gap="4"
        class="form-modal-footer"
      >
        <!-- Accept -->
        <AppButton
          size="xl"
          type="submit"
          :loading="loading"
          :text="acceptText || $t('General.SaveChanges')"
        />

        <!-- Cancel -->
        <AppButton
          variant="primary"
          type="button"
          :outline="true"
          size="xl"
          :text="$t('General.Cancel')"
          @on-clicked="$emit('handleCancel')"
        />
      </AppFlex>
    </slot>
  </Form>
</template>