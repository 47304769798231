import { ref, computed } from "vue"

export const useAppMultiSelect = (props, emit) => {
  // Reactive reference to track whether the menu is visible or not
  const menuIsVisible = ref(false)

  // Reactive reference to save the filter text
  const text = ref("")

  // Reactive array to manage the filtered options
  const filteredOptions = ref(props?.options || [])

  // Define a reactive reference to hold the selected options
  const selectedOptions = ref(props.modelValue || [])

  // Filters the available options based on the search text and shows the dropdown.
  const handleTextChange = () => {
    const textFilter = text.value?.toLowerCase()
    if (!textFilter) {
      filteredOptions.value = props?.options
      return
    }
    filteredOptions.value = props?.options?.filter(option => {
      const optionText = `${option?.[props?.displayProperty]}`.toLowerCase()
      return optionText.includes(textFilter)
    })
  }


  // Compute the placeholder text for the selector based on selected options
  const selectorPlaceholder = computed(() => {
    if (selectedOptions.value.length === 0) return props.placeholder
    return selectedOptions.value.map(option => getSelectedOptionText(option)).join(", ")
  })

  // Check if an option is selected
  const isSelected = optionId => {
    return selectedOptions.value.some(selectedOption => +selectedOption === +optionId )
  }

  // Update the selection based on user interaction
  const updateSelection = optionId => {
    const index = selectedOptions.value.findIndex(selectedOptionId => +selectedOptionId === +optionId)

    if (index !== -1) {
      selectedOptions.value = selectedOptions.value.filter(
        selectedOptionId => +selectedOptionId !== +optionId
      )
    } else {
      selectedOptions.value = [...selectedOptions.value, optionId]
    }
    emit("update:modelValue", selectedOptions.value)
  }

  const removeItem = option => {
    updateSelection(option)
  }

  // Function to open menu
  const handleToggleMenu = () => {
    if (props.loading) return
    menuIsVisible.value = !menuIsVisible.value
  }

  const getSelectedOptionText = option => {
    return props?.options?.find(op => {
      return +option === +op?.[props?.valueProperty]
    })?.[props?.displayProperty]
  }

  return {
    // States
    filteredOptions,
    menuIsVisible,
    selectedOptions,
    selectorPlaceholder,
    text,

    // Functions
    getSelectedOptionText,
    handleTextChange,
    handleToggleMenu,
    isSelected,
    removeItem,
    updateSelection
  }
}
