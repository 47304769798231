import { useI18n } from "vue-i18n"

import { APP_ENTITY, ICON, ROLES, ROUTE_NAMES } from "@/lib/constants"
import { useAuthStore } from "@/stores"

export const useSidebarLinkList = () => {
  const { t } = useI18n()
  const auth = useAuthStore()

  const links = {
    admin: [
      {
        icon: ICON.TRUCK,
        text: t("Menu.Routes"),
        name: ROUTE_NAMES.ADMIN.ROUTES.PANEL,
        visible: true
      },
      {
        icon: ICON.MAP_PIN,
        text: t("Menu.PDI"),
        name: ROUTE_NAMES.ADMIN.PDIS.LIST,
        visible: auth.isRole(ROLES.SUPER_ADMIN)
      },
      {
        icon: ICON.USERS,
        text: t("Menu.Users"),
        name: ROUTE_NAMES.ADMIN.USERS,
        visible: true
      },
      {
        icon: ICON.CHART_BAR_SQUARE,
        text: t("Menu.Reports"),
        name: ROUTE_NAMES.ERRORS.NOT_FOUND,
        visible: true
      },
      {
        icon: ICON.CHART_PIE,
        text: t("Menu.Dashboard"),
        name: ROUTE_NAMES.ERRORS.NOT_FOUND,
        visible: true
      },
      {
        icon: ICON.COG_6_TOOTH,
        text: t("Menu.Settings"),
        name: ROUTE_NAMES.ADMIN.SETTINGS,
        visible: auth.isRole(ROLES.SUPER_ADMIN)
      }
    ],
    provider: [
      {
        icon: ICON.MAP,
        text: t("Menu.ContractedRoutes"),
        name: ROUTE_NAMES.PROVIDERS.CONTRACTED_ROUTES,
        visible: true
      },
      {
        icon: ICON.CALENDAR_DAYS,
        text: t("Menu.DailyRoutes"),
        name: ROUTE_NAMES.PROVIDERS.DAILY_ROUTES,
        visible: true
      },
      {
        icon: ICON.INBOX_ARROW_DOWN,
        text: t("Menu.Retainers"),
        name: ROUTE_NAMES.PROVIDERS.RETAINERS,
        visible: true
      },
      {
        icon: ICON.USERS,
        text: t("Menu.Drivers"),
        name: ROUTE_NAMES.PROVIDERS.DRIVERS,
        visible: true
      },
      {
        icon: ICON.TRUCK,
        text: t("Menu.Vehicles"),
        name: ROUTE_NAMES.PROVIDERS.VEHICLES,
        visible: true
      },
      {
        icon: ICON.WRENCH,
        text: t("Menu.Trailers"),
        name: ROUTE_NAMES.PROVIDERS.TRAILERS,
        visible: true
      }
    ]
  }

  return {
    linkItems: links[APP_ENTITY]?.filter?.(link => link?.visible)
  }
}