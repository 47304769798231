import { computed } from "vue"

export const useAppSwtich = props => {
  const { size, variant, muted } = props

  const swtichClasses = computed(() => {
    const classes = ["form-check-input", "pointer", "round-hover"]

    classes.push(
      // Size
      ...([`app-switch-size-${size}`]),

      // Muted
      ...(muted ? ["app-swtich-muted"] : [])
    )
    return classes
  })

  const containerClasses = computed(() => {
    const classes = ["d-flex", "flex-row-reverse", "align-items-center"]

    classes.push(
      // Variant
      ...(variant === "card" ? ["app-switch-card"]: []),

      // Sm position
      ...(size === "sm" ? ["gap-2"] : []),

      // Lg Position
      ...(size === "lg" ? ["gap-3"] : [])
    )
    return classes
  })

  return {
    swtichClasses,
    containerClasses
  }
}