import { createApp, h } from "vue"

export const createVueComponent = (component, props) => {
  // Create a new Vue component
  const currentComponent = createApp({
    render: () => h(component, props)
  })

  // Mount the Vue component onto a temporary element
  const container = document.createElement("div")
  currentComponent.mount(container)

  // Return the DOM element of the mounted Vue component
  return container.firstElementChild
}
