<script>
export default {
  name: "AppTableColIcon"
}
</script>

<script setup>
import { computed } from "vue"

import { AppIcon } from "@/components/ui"
import { ICON } from "@/lib/constants"

const props = defineProps({ direction: String })

const currentSortIcon = computed(() => {
  const iconSrc = {
    all: ICON.ARROWS_UP_DOWN,
    asc: ICON.ARROW_UP,
    desc: ICON.ARROW_DOWN
  }[props?.direction || "all"]

  return iconSrc
})
</script>

<template>
  <AppIcon
    :name="currentSortIcon"
    role="button"
    class="app-table-th-icon"
    variant="solid"
    color="primary"
    size="20"
  />
</template>

<style
  src="../../styles/app-table.styles.scss"
  lang="scss"
  scoped
/>