<script>
export default {
  name: "UserProfileHeader"
}
</script>

<script setup>
import { computed } from "vue"

import { AppLabels } from "@/components/common"
import { AppFlex } from "@/components/layouts"
import { AppIcon, AppLink } from "@/components/ui"
import { useWindowSize } from "@/composables"
import { APP_ENTITY, ICON, ROUTE_NAMES } from "@/lib/constants"
import { useAuthStore, useUiStore } from "@/stores"

const store = useAuthStore()

const ui = useUiStore()
const { isMobile } = useWindowSize()

const handleLinkClick = () => {
  if (isMobile.value) ui.toogleSidebar()
}

const currentEntityProfileUrlName = computed(() => {
  return {
    provider: ROUTE_NAMES.PROVIDERS.PROFILE,
    admin: ROUTE_NAMES.ADMIN.PROFILE
  }[APP_ENTITY]
})
</script>

<template>
  <AppLink
    :to="{ name: currentEntityProfileUrlName }"
    class="round-hover-12"
    @click="handleLinkClick"
  >
    <AppFlex gap="3" class="px-2">
      <!-- Icon -->
      <AppIcon
        :name="ICON.USER_CIRCLE"
        variant="solid"
        class="mt-2"
        size="30"
      />

      <!-- Texts -->
      <AppLabels
        :title="store?.user?.Name"
        :subtitle="store?.user?.Email"
        text-type="white"
      />
    </AppFlex>
  </AppLink>
</template>
