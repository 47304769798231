<script>
export default {
  name: "AppLogo"
}
</script>

<script setup>
import { AppLink, AppImage } from "@/components/ui"
import { ROUTE_PATHS, UI } from "@/lib/constants"
</script>

<template>
  <AppLink :to="{ path: ROUTE_PATHS.HOME }" class="round-hover">
    <AppImage
      :src="UI.IMAGE_PATHS.LOGO"
      alt="gls"
    />
  </AppLink>
</template>
