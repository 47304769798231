<script>
export default {
  name: "AppTextInput",
  inheritAttrs: true
}
</script>

<script setup>
import { onMounted, ref, toRef, watch } from "vue"

import { useField } from "vee-validate"

import { AppIcon, AppText } from "../.."
import AppField from "../field/AppField.vue"

import { ICON } from "@/lib/constants"

const props = defineProps({
  modelValue: [String, Number],
  name: {
    type: String,
    required: true
  },
  type: {
    type: String,
    default: "text"
  },
  label: {
    type: String,
    default: null
  },
  placeholder: {
    type: String,
    default: null
  },
  alias: {
    type: String,
    required: true
  },
  focus: {
    type: Boolean,
    default: false
  },
  helpText: {
    type: String,
    default: null
  },
  disabled: {
    type: Boolean,
    default: false
  },
  icon: {
    type: String,
    default: null
  },
  autocomplete: {
    type: String,
    default: "on",
    validator: value => ["on", "off"].includes(value)
  }
})
defineEmits(["update:ModelValue"])

onMounted(() => {
  if (props.focus) {
    setTimeout(() => inputRef.value?.focus(), 500)
  }
})

const inputRef = ref(null)
const currentTextType = ref(props.type)
const modelValue = toRef(props, "modelValue")

const { errorMessage, value: inputValue } = useField(() => props.name, undefined, {
  validateOnMount: false,
  validateOnValueUpdate: true,
  type: "default",
  initialValue: props.modelValue,
  valueProp: props.modelValue
})

const togglePasswordVisibility = () => {
  const oldValue = currentTextType.value
  currentTextType.value = oldValue === "password" ? "text" : "password"
}

watch(modelValue, newValue => {
  inputValue.value = newValue
})
</script>

<template>
  <AppField
    :alias="alias"
    :label="label"
    :help-text="helpText"
  >
    <div class="input-group">
      <!-- Icon -->
      <span v-if="icon" class="input-group-text">
        <AppIcon
          :name="icon"
          color="primary"
          size="20"
        />
      </span>

      <!-- Text Input -->
      <input
        :id="alias"
        ref="inputRef"
        v-model="inputValue"
        :name="name"
        :disabled="disabled"
        :autocomplete="autocomplete"
        :placeholder="placeholder || $t('General.WriteHere') "
        :type="currentTextType"
        :class="['form-control', { 'input-border-left-none': Boolean(icon) }]"
        @change="$emit('update:ModelValue', $event.target.value)"
      >
    </div>

    <!-- Password Icon container  -->
    <div
      v-if="type === 'password'"
      class="password-icon-container"
    >
      <AppIcon
        :name="currentTextType === 'password' ? ICON.EYE : ICON.EYE_SLASH"
        class="me-3 pointer password-icon"
        color="muted"
        variant="solid"
        role="button"
        size="24"
        @click="togglePasswordVisibility"
      />
    </div>

    <!-- Error Text -->
    <AppText
      v-if="errorMessage"
      type="danger"
      tag="small"
      lh="sm"
    >
      {{ errorMessage }}
    </AppText>
  </AppField>
</template>

<style lang="scss" scoped>
.password-icon-container {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  height: 0px;
  position: relative;
  top: -34px;
  user-select: none;

  .password-icon {
    z-index: $z-index-password-icon;
  }
}

input {
  border-radius: 8px;
}
</style>