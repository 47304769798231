<script>
export default {
  name: "AppTooltip"
}
</script>

<script setup>
import { computed, ref } from "vue"

const props = defineProps({
  text: {
    type: String,
    default: ""
  },
  position: {
    type: String,
    default: "right"
  },
  tooltipId: {
    type: String,
    default: "tooltip__text"
  },
  minWidth: {
    type: Number,
    default: 300
  },
  closeOnMouseLeave: {
    type: Boolean,
    default: true
  },
  variant: {
    type: String,
    default: null,
    validator: value => ["primary", "secondary", "warning", "dark", "white"].includes(value)
  }
})

const contentIsVisible = ref(false)

const tooltipClasses = computed(() => {
  const classes = ["fade-in", "tooltip__text"]

  classes.push(
    // Variant
    ...(props.variant ? [`bg-${props?.variant}`] : []),

    // Position
    ...([`tooltip--${props.position}`])
  )
  return classes
})

const computedStyles = computed(() => ({
  "min-width": `${props?.minWidth}px`
}))

const handleMouseLeave = () => {
  if (!props.closeOnMouseLeave) return
  contentIsVisible.value = false
}
</script>

<template>
  <div class="tooltip-wrapper">
    <div
      @mouseenter="contentIsVisible = true"
      @mouseleave="handleMouseLeave"
    >
      <slot />
    </div>
    <div
      v-if="contentIsVisible"
      :id="tooltipId"
      :class="tooltipClasses"
      :style="computedStyles"
      role="tooltip"
    >
      <div
        v-click-outside="() => (contentIsVisible = false)"
        @mouseleave="contentIsVisible = false"
      >
        <slot name="content">
          {{ text }}
        </slot>
      </div>
    </div>
  </div>
</template>

<style
  scoped
  lang="scss"
  src="./styles/app-tooltip.styles.scss"
/>
