<script>
export default {
  name: "AppEmpty"
}
</script>

<script setup>
import { AppFlex } from "@/components/layouts"
import { AppImage, AppText } from "@/components/ui"
import { UI } from "@/lib/constants"

defineProps({ text: String })
</script>

<template>
  <AppFlex class="content" justify="center" align="center" gap="3">
    <!-- Image -->
    <AppImage
      alt="empty"
      :src="UI.IMAGE_PATHS.EMPTY"
    />

    <!-- Text -->
    <AppText type="muted">
      {{ text || $t('General.NoRecords') }}
    </AppText>
  </AppFlex>
</template>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 160px;
  background-color: $grey-250;
}
</style>
