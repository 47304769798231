<script>
export default {
  name: "AppBodyTableEmpty"
}
</script>

<script setup>
import { AppEmpty } from "@/components/common"

defineProps({
  colspan: {
    type: Number,
    required: true
  },
  emptyText: {
    type: String,
    default: null
  }
})
</script>

<template>
  <tr>
    <td
      :colspan="colspan"
      class="empty-table-cell"
    >
      <AppEmpty :text="emptyText" />
    </td>
  </tr>
</template>

<style
  src="../../styles/app-table.styles.scss"
  lang="scss"
  scoped
/>