<script>
export default {
  name: "AppScrollUp"
}
</script>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue"

import { AppIcon } from "@/components/ui"
import { ICON } from "@/lib/constants"
import { scroll } from "@/lib/services"

const buttonIsVisible = ref(false)

const handleScroll = () => {
  buttonIsVisible.value = window.scrollY > 10
}

onMounted(() => {
  window.addEventListener("scroll", handleScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll)
})
</script>

<template>
  <button
    v-if="buttonIsVisible"
    class="btn btn-primary animate-up scroll-up-button btn-circle round-hover text-white"
    @click="scroll.toTop()"
  >
    <AppIcon
      :name="ICON.ARROW_UP"
      size="25"
    />
  </button>
</template>

<style scoped lang="scss">
.scroll-up-button {
  position: fixed;
  right: 20px;
  bottom: 20px;
}
</style>
