<script>
export default {
  name: "AuthLayout"
}
</script>

<script setup>
import { computed } from "vue"

import { AppFlex } from ".."

import { AppCard } from "@/components/ui"
import { UI } from "@/lib/constants"

const props = defineProps({
  title: String,
  variant: {
    type: String,
    validator: value => ["login", "password"].includes(value)
  }
})

const cardMaxWidth = computed(() => {
  const width = {
    login: UI.AUTH_WIDTH.LOGIN,
    password: UI.AUTH_WIDTH.FORGOT_PASSWORD
  }[props.variant]
  return width
})

</script>

<template>
  <AppFlex justify="center">
    <AppCard
      :title="title"
      class="mt-l4 mx-2 w-100 fade-in"
      :style="`max-width: ${cardMaxWidth}px`"
    >
      <slot />
    </AppCard>
  </AppFlex>
</template>
