import { createApp } from "vue"

import { AppLoader } from "@/components/ui"

// Install function to add global methods to the Vue app
const install = app => {
  let container
  let loaderApp
  const loaderPlugId = "app-loading-plug"

  // Function to display the loader component
  const showLoader = () => {
    // Create a new div element for the loader
    container = document.createElement("div")
    container.setAttribute("id", loaderPlugId)

    // Append the loader container to the body of the document
    document.body.appendChild(container)

    // Create a new Vue app instance for the loader component
    loaderApp = createApp(AppLoader)
    // Mount the loader app to the container
    loaderApp.mount(container)
  }

  // Function to close the loader component
  const closeLoader = () => {
    // Check if the loader app instance exists
    if (loaderApp) {
      // Unmount the loader app from the container
      loaderApp.unmount(container)
    }
    // Find the loader container in the document
    container = document.querySelector(`#${loaderPlugId}`)
    // Remove the loader container from the body if found
    if (container)
      document.body.removeChild(container)
  }

  // Add the showLoader and closeLoader functions to the global properties of the Vue app
  app.config.globalProperties.$showLoader = showLoader
  app.config.globalProperties.$closeLoader = closeLoader
}

export default install
