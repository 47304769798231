<script>
export default {
  name: "AppMatrix"
}
</script>

<script setup>
import { AppScroll } from "@/components/layouts"

defineProps({
  data: {
    type: Array,
    required: true
  }
})
</script>

<template>
  <AppScroll>
    <table class="table">
      <thead>
        <tr>
          <th />
          <!-- Name -->
          <th
            v-for="place in data"
            :key="place?.name"
            class="bg-soft-blue"
          >
            {{ place?.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <!-- Matrix Data -->
        <tr
          v-for="place in data"
          :key="place?.name"
        >
          <!-- Name -->
          <td class="app-matrix-place">
            {{ place?.name }}
          </td>

          <!-- Distances -->
          <td
            v-for="distance in place?.distances"
            :key="distance?.name"
            :class="{
              'bg-grey-100': place?.name === distance?.name,
              'text-danger': +distance?.distance === 0
            }"
          >
            {{ distance?.distance }}
          </td>
        </tr>
      </tbody>
    </table>
  </AppScroll>
</template>

<style
  src="./styles/app-matrix.styles.scss"
  lang="scss"
  scoped
/>