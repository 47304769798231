import { ref } from "vue"

import { useAxios, usePlugins } from "@/composables"

export const useFetch = ({
  url,
  method,
  headers = { headers: { "Content-Type": "application/json" } },
  useLoader = false
}) => {
  // Use Axios instance from useAxios composable
  const { request } = useAxios()
  const { $showLoader, $closeLoader } = usePlugins()

  // Reactive references for state management
  const errors = ref({})
  const isSubmitting = ref(false)

  // Retrieve the first error for a given field
  const errorFor = field => (errors.value[field] ? errors.value[field][0] : null)

  // Build FormData object from given data
  const buildFormData = data => {
    const form = new FormData()
    for (const [key, value] of Object.entries(data)) {
      if (value instanceof File) {
        form.append(key, value)
      } else if (typeof value === "object") {
        form.append(key, value ? JSON.stringify(value) : "")
      } else {
        form.append(key, value ?? "")
      }
    }
    return form
  }

  // Submit the request with provided data
  const submit = async (formData = {}, success, error) => {
    // Reset errors and set submitting state
    errors.value = {}
    isSubmitting.value = true

    try {
      if (useLoader) $showLoader()

      // Build payload based on content type
      const payload = headers.headers["Content-Type"] === "multipart/form-data"
        ? buildFormData(formData)
        : formData

      // Make the request
      const { data } = await request({ method, url, data: payload, headers })

      // Clear errors and call success callback with data
      errors.value = {}
      if (success) success(data)
    } catch (e) {
      console.warn({ e })
      // Handle request errors
      if (e.response && error) {
        // Call error callback with error message and status code
        error({
          status: e?.response?.status,
          message: e?.response?.data?.messages?.[0]
        })
      }
    } finally {
      if (useLoader) $closeLoader()
      // Reset submitting state
      isSubmitting.value = false
    }
  }

  return {
    // Expose states
    isSubmitting,

    // Expose functions
    errorFor,
    submit
  }
}
