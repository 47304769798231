<script>
export default {
  name: "AppCol"
}
</script>

<script setup>
import { AppFlex } from ".."

import { AppText } from "@/components/ui"

defineProps({
  text: {
    type: String,
    default: null
  },
  subtext: {
    type: String,
    default: null
  },
  gap: {
    type: String,
    default: "3"
  }
})
</script>

<template>
  <div>
    <!-- Text -->
    <div v-if="text" class="mb-1">
      <AppText weight="bold">
        {{ text }}
      </AppText>
    </div>

    <!-- Sub Text -->
    <div v-if="subtext" class="my-1">
      <AppText tag="small">
        {{ subtext }}
      </AppText>
    </div>


    <!-- Content -->
    <AppFlex
      justify="between"
      :gap="gap"
      class="col-layout"
    >
      <!-- Left -->
      <div class="col-left">
        <slot name="left" />
      </div>

      <!-- Rigth -->
      <div class="col-right">
        <slot name="right" />
      </div>
    </AppFlex>
  </div>
</template>

<style
  src="./styles/app-col.styles.scss"
  lang="scss"
  scoped
/>
