import { defineStore } from "pinia"

import { UI } from "@/lib/constants"

export default defineStore({
  id: "ui",
  state: () => ({
    sidebarIsOpen: false
  }),

  // Actions
  actions: {
    // Manage Sidebar reactivity
    toogleSidebar() {
      this.sidebarIsOpen = !this.sidebarIsOpen
    }
  },

  // Getters
  getters: {
    // Manage Sidebar Width
    sidebarWith(state) {
      return {
        open: UI.HEADER_WIDTH.OPEN,
        closed: UI.HEADER_WIDTH.CLOSED
      }[state.sidebarIsOpen ? "open": "closed"]
    },

    // Manage Header Styles
    headerStyles() {
      return {
        width: `calc(100% - ${this.sidebarWith}px)`,
        left: `${this.sidebarWith}px`
      }
    }
  }
})
