<script>
export default {
  name: "AppPagination"
}
</script>

<script setup>
import { toRef } from "vue"

import { AppBadge, AppButton, AppText } from ".."

import { AppFlex } from "@/components/layouts"
import { ICON } from "@/lib/constants"

const props = defineProps({
  currentPage: Number,
  selectedPerPage: [String, Number],
  totalPages: Number,
  totalRecords: Number
})

const emit = defineEmits(["updatePerPage", "updatePage"])

const perPageSelectedValue = toRef(props?.selectedPerPage)

const handlePaginationChange = page => {
  emit("updatePage", page)
}

const handlePerPageSelectedChange = event => {
  const newPerPageSelected = event?.target?.value
  emit("updatePerPage", newPerPageSelected)
}
</script>

<template>
  <div class="pagination-content">
    <!-- Text -->
    <span class="pagination-info-text mt-sm-1">
      {{ $t("Pagination.Show", { currentPage, totalPages, totalRecords }) }}
    </span>

    <!-- Pagination Buttons -->
    <div class="page-buttons mt-sm-1">
      <!-- First Page -->
      <AppButton
        v-if="currentPage > 2"
        :icon="ICON.CHEVRON_DOUBLE_LEFT"
        variant="primary"
        :outline="true"
        @click="handlePaginationChange(1)"
      />

      <!-- Before Page-->
      <AppButton
        v-if="currentPage !== 1"
        :icon="ICON.CHEVRON_LEFT"
        variant="primary"
        :outline="true"
        @click="handlePaginationChange(currentPage - 1)"
      />

      <!-- Current Page -->
      <AppBadge
        type="primary"
        shape="pill"
        class="current-page-badge"
        :text="currentPage"
      />

      <!-- After Page -->
      <AppButton
        v-if="currentPage < totalPages"
        variant="primary"
        :outline="true"
        :icon="ICON.CHEVRON_RIGHT"
        @click="handlePaginationChange(currentPage + 1)"
      />

      <!-- Last Page -->
      <AppButton
        v-if="currentPage < totalPages - 1"
        :icon="ICON.CHEVRON_DOUBLE_RIGHT"
        variant="primary"
        :outline="true"
        @click="handlePaginationChange(totalPages)"
      />
    </div>

    <AppFlex gap="2" class="pagination-end mt-sm-1">
      <!-- Page size Selector -->
      <select
        v-model="perPageSelectedValue"
        class="form-select pagination-dropdown"
        @change="handlePerPageSelectedChange"
      >
        <option
          v-for="option in [5, 10, 15, 20]"
          :key="option"
          :value="option"
        >
          {{ option }}
        </option>
      </select>

      <!-- Results Text -->
      <AppText class="text-sm-center">
        {{ $t("Pagination.Results") }}
      </AppText>
    </AppFlex>
  </div>
</template>

<style
  src="./styles/app-pagination.styles.scss"
  lang="scss"
  scoped
/>
