<script>
export default {
  name: "AppSelect"
}
</script>

<script setup>
import { onMounted, ref, toRef, watch } from "vue"

import { useField } from "vee-validate"

import { AppSpinner, AppText } from "../.."
import AppField from "../field/AppField.vue"

import { helper } from "@/lib/services"

const props = defineProps({
  modelValue: [String, Number],
  name: {
    type: String,
    required: true
  },
  label: {
    type: String,
    default: null
  },
  placeholder: {
    type: String,
    default: null
  },
  alias: {
    type: String,
    required: true
  },
  focus: {
    type: Boolean,
    default: false
  },
  helpText: {
    type: String,
    default: null
  },
  loading: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  autocomplete: {
    type: String,
    default: "on",
    validator: value => ["on", "off"].includes(value)
  },
  options: {
    type: Array,
    required: true,
    default: []
  },
  valueProperty: {
    type: String,
    default: "Value"
  },
  displayProperty: {
    type: String,
    default: "Name"
  },
  hideDefaultOption: {
    type: Boolean,
    required: false
  },
  useValidation: {
    type: Boolean,
    default: false
  },
  black: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(["update:modelValue"])

const inputRef = ref(null)
const modelValue = toRef(props, "modelValue")

const { errorMessage, value: inputValue } = useField(() => props.name, undefined, {
  validateOnMount: false,
  validateOnValueUpdate: true,
  initialValue: props.useValidation ? undefined : props.modelValue || "",
  valueProp: props.modelValue
})

const updateSelection = event => {
  emit("update:modelValue", event.target.value)
}

watch(modelValue, newValue => {
  inputValue.value = newValue
})

onMounted(() => {
  if (props.focus) {
    setTimeout(() => inputRef.value?.focus(), 500)
  }
  if (helper.isNullFalsy(inputValue.value)) {
    inputValue.value = ""
  }
})
</script>

<template>
  <AppField
    :alias="alias"
    :label="label"
    :help-text="helpText"
  >
    <!-- Select Input -->
    <select
      :id="alias"
      ref="inputRef"
      v-model="inputValue"
      :name="name"
      :disabled="disabled || loading"
      :autocomplete="autocomplete"
      :class="['form-select', {'black': black}]"
      @change="updateSelection($event)"
    >
      <!-- Default option -->
      <option
        v-if="!hideDefaultOption"
        value=""
        selected
        class="first-option pointer"
      >
        {{ placeholder || $t('General.All') }}
      </option>

      <!-- Options -->
      <option
        v-for="option in options"
        :key="option[valueProperty]"
        :value="option[valueProperty]"
        class="form-select-option"
      >
        {{ option[displayProperty] }}
      </option>
    </select>

    <!-- Loader -->
    <div v-if="loading" class="app-select-loading">
      <AppSpinner />
    </div>

    <!-- Error Text -->
    <AppText
      v-if="errorMessage"
      type="danger"
      tag="small"
      lh="sm"
    >
      {{ errorMessage }}
    </AppText>
  </AppField>
</template>

<style
  src="./styles/app-select.styles.scss"
  lang="scss"
  scoped
/>
