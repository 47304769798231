<script>
export default {
  name: "AppChangePasswordModal"
}
</script>

<script setup>
import { AppFlex, AppForm } from "@/components/layouts"
import { AppAlert, AppModal, AppText } from "@/components/ui"
import { AppTextInput } from "@/components/ui/_fields"
import { useCrud } from "@/composables"
import { ENDPOINTS } from "@/lib/constants"
import { changePasswordFormOptions } from "@/lib/form-options"

defineProps({ visible: Boolean })

const emit = defineEmits(["handleClose"])

const { editRequest } = useCrud(ENDPOINTS.AUTH.PROFILE_CHANGE_PASSWORD)

const handleSubmit = async payload => {
  await editRequest(null, payload, () => emit("handleClose"))
}
</script>

<template>
  <div>
    <AppModal
      size="xl"
      type="form"
      :visible="visible"
      :title="$t('Profile.ChangePassword')"
      :accept-text="$t('General.SaveChanges')"
      :footer-visible="false"
    >
      <!-- Info -->
      <AppAlert
        :text="$t('Profile.PasswordHelperText')"
        :uppercase="false"
        variant="info"
        class="mt-3"
      />

      <!-- Form -->
      <AppForm
        :initial-values="changePasswordFormOptions.data"
        :schema="changePasswordFormOptions.schema"
        :footer-type="'modal'"
        autocomplete="off"
        @handleCancel="$emit('handleClose')"
        @submit="handleSubmit"
      >
        <AppFlex direction="col">
          <!-- Old Password -->
          <AppFlex class="mt-4" justify="between" xsdir="col">
            <AppText tag="label" for="PasswordCurrent" class="mt-2" weight="bold">
              {{ $t('Profile.OldPassword') }}
            </AppText>

            <AppTextInput
              autocomplete="off"
              type="password"
              name="PasswordCurrent"
              alias="PasswordCurrent"
              :placeholder="$t('Profile.OldPassword')"
              class="mb-3 w-80 w-sm-100"
            />
          </AppFlex>

          <!-- New Password -->
          <AppFlex class="mt-4" justify="between" xsdir="col">
            <AppText tag="label" for="PasswordNew" class="mt-2" weight="bold">
              {{ $t('Auth.Password.NewPassword') }}
            </AppText>

            <AppTextInput
              autocomplete="off"
              type="password"
              name="PasswordNew"
              alias="PasswordNew"
              :placeholder="$t('Auth.Password.NewPassword')"
              class="mb-3 w-80 w-sm-100"
            />
          </AppFlex>

          <!-- Confirm Password -->
          <AppFlex class="mt-4 mb-3" justify="between" xsdir="col">
            <AppText tag="label" for="PasswordConfirm" class="mt-2" weight="bold">
              {{ $t('Auth.Password.ConfirmPassword') }}
            </AppText>

            <AppTextInput
              autocomplete="off"
              type="password"
              name="PasswordConfirm"
              alias="PasswordConfirm"
              :placeholder="$t('Auth.Password.ConfirmPassword')"
              class="mb-3 w-80 w-sm-100"
            />
          </AppFlex>
        </AppFlex>
      </AppForm>
    </AppModal>
  </div>
</template>
