<script>
export default {
  name: "AppCheckbox",
  inheritAttrs: true
}
</script>

<script setup>
import { ref, watch } from "vue"

import { useField } from "vee-validate"

import { AppText } from "../.."

const props = defineProps({
  modelValue: Boolean,
  name: {
    type: String,
    required: true
  },
  label: {
    type: String,
    required: true
  },
  alias: {
    type: String,
    required: true
  }
})

const modelValue = ref(props?.modelValue)

const emit = defineEmits(["update:modelValue"])

const { checked, handleChange, errorMessage } = useField(props.name, undefined, {
  type: "checkbox",
  checkedValue: true,
  uncheckedValue: false,
  initialValue: modelValue.value || false
})

watch(modelValue, newValue => {
  checked.value = newValue
})

const updateCheckboxValue = value => {
  emit("update:modelValue", value)
  handleChange(value)
}

</script>

<template>
  <div class="form-check d-flex gap-3">
    <input
      :id="alias"
      type="checkbox"
      class="form-check-input pointer round-hover"
      :name="name"
      :checked="checked"
      @input="e => updateCheckboxValue(e.target.checked)"
    >

    <!-- Default Text -->
    <AppText
      tag="label"
      class="form-check-label mt-1"
      :for="alias"
    >
      {{ label }}
    </AppText>

    <!-- Error Label -->
    <div v-if="errorMessage" class="ml-3">
      <AppText
        tag="small"
        type="danger"
        class="mb-2"
      >
        {{ errorMessage }}
      </AppText>
    </div>
  </div>
</template>

<style scoped lang="scss">
input[type="checkbox"] {
    width: 23px;
    height: 23px;
    color: $primary !important;
}
</style>