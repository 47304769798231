<script>
export default {
  name: "AppStopItem"
}
</script>

<script setup>
import { ref } from "vue"

import { AppFlex } from "@/components/layouts"
import { AppButtonCollapse, AppText } from "@/components/ui"

defineProps({ stop: Object })

const timesAreVisible = ref(false)
</script>

<template>
  <div>
    <AppFlex justify="between" align="center">
      <!-- PDI -->
      <AppText weight="bold" size="6">{{ stop?.Name }}</AppText>

      <!-- Collapse -->
      <AppButtonCollapse
        :visible="timesAreVisible"
        @updateVisibility="timesAreVisible = !timesAreVisible"
      />
    </AppFlex>

    <AppFlex
      v-if="timesAreVisible"
      justify="between"
      xsdir="col"
      class="p-3 mt-3 rounded bg-soft-blue"
    >
      <!-- Arrival -->
      <AppFlex direction="col" gap="2">
        <AppText weight="bold" size="6">{{ $t('General.Arrival') }}</AppText>
        <AppText size="6">
          {{ $t('Admin.RouteDetails.EstimatedTime', { value: stop?.ArrivalHourPlanned }) }}
        </AppText>
      </AppFlex>

      <!-- Exit -->
      <AppFlex direction="col" gap="2">
        <AppText weight="bold" size="6">{{ $t('General.Exit') }}</AppText>
        <AppText size="6">
          {{ $t('Admin.RouteDetails.EstimatedTime', { value: stop?.ExitHourPlanned }) }}
        </AppText>
      </AppFlex>
    </AppFlex>
  </div>
</template>
