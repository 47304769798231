class ArrayService {
  // Method to generate an array with values from a (inclusive) to b (inclusive)
  getRange(a, b) {
    return Array.from({ length: b - a + 1 }, (_, i) => a + i)
  }

  // Method to get the first n elements of an array
  getFirstElements(array, n) {
    if (!array?.length <= n) return array
    return array.slice(0, n)
  }

  // Method to generate a selectors data with an array of numbers
  getNumbersSelector(data) {
    if (!data) return []
    return data?.map(item => ({ Name: item, Value: item }))
  }

  // Method to generate a string from an array
  toString(array) {
    if (!Array.isArray(array) || array?.length === 0) return ""
    return array.join(", ")
  }

  assign(reactiveData, newData) {
    Object.assign(reactiveData, newData)
  }
}

const array = new ArrayService()
export default array
