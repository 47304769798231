import { computed } from "vue"

import { usePlugins } from "@/composables"
import { tc } from "@/lib/services"

export const useAppDateRangeInput = ({ endRef, startRef, emit }) => {
  const { $toast } = usePlugins()

  const currentDateText = computed(() => {
    if (!startRef.value && !endRef.value) return ""
    return `${startRef.value || ""} - ${endRef.value || ""}`
  })

  // Show Toast message
  const showToast = (title, message) => {
    $toast({
      title: tc(title),
      message: tc(message),
      type: "info"
    })
  }

  // Handle start date change
  const handleStartDateChange = e => {
    const newValue = e.target.value

    if (!newValue) {
      startRef.value = null
      endRef.value = null

      const payload = {
        start: startRef.value,
        end: endRef.value
      }

      emit("update", payload)
      return
    }

    if (newValue >= endRef.value) {
      showToast("General.Warning", "General.StartDateError")
      startRef.value = null
    } else {
      startRef.value = newValue
    }
  }

  // Handle end date change
  const handleEndDateChange = e => {
    const newValue = e.target.value

    if (!startRef.value) {
      showToast("General.Warning", "General.StartDateErrorRequired")
      endRef.value = null
    } else if (newValue <= startRef.value) {
      showToast("General.Warning", "General.EndDateError")
      endRef.value = null
    } else {
      endRef.value = newValue

      const payload = {
        start: startRef.value,
        end: endRef.value
      }

      emit("update", payload)
    }
  }

  return {
    currentDateText,
    handleStartDateChange,
    handleEndDateChange
  }
}
