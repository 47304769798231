import { defineStore } from "pinia"

import { useLocalStorage } from "@/composables"
import { UI } from "@/lib/constants"

export default defineStore({
  id: "auth",
  state: () => ({
    user: useLocalStorage(UI.LOCALSTORAGE.CURRENT_USER_KEY)?.value || null
  }),

  // Getters
  getters: {
    // Is Auth
    isAuthenticated(state) {
      return Boolean(state.user)
    },

    // Check user role
    isRole: state => role => {
      return state.user?.RoleID === +role
    }
  },

  // Actions (mutations)
  actions: {
    setUser(user) {
      this.user = user
    },
    logout() {
      this.user = null
    }
  }
})
