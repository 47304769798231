<script>
export default {
  name: "AppAddressColumn"
}
</script>

<script setup>
import { AppFlex } from "@/components/layouts"
import { AppCard, AppIcon, AppText, AppTooltip } from "@/components/ui"
import { usePlugins } from "@/composables"
import { ICON } from "@/lib/constants"
import { helper, tc } from "@/lib/services"

const { data, isFirst } = defineProps({
  data: Object,
  isFirst: Boolean
})

const { $toast } = usePlugins()

const handleCopy = () => {
  helper.copy(data?.Street)
  $toast({
    title: tc("General.SuccessCopy"),
    message: tc("General.AddressCopy")
  })
}
</script>

<template>
  <div>
    <AppFlex gap="2" align="center">
      <!-- Province -->
      <AppText class="mt-1">
        {{ data?.Province }}
      </AppText>

      <!-- Tooltip container -->
      <AppTooltip
        :position="isFirst ? 'bottom-left' : 'left'"
        :close-on-mouse-leave="false"
      >
        <!-- Help icon -->
        <AppIcon
          :name="ICON.INFORMATION_CIRCLE"
          color="primary"
          size="20"
          class="round-hover"
        />

        <!-- Tooltip content -->
        <template #content>
          <AppCard>
            <template #content>
              <AppFlex direction="col" gap="2">
                <!-- Province -->
                <AppText weight="bold">{{ data?.Province }}</AppText>

                <AppFlex justify="between">
                  <!-- Address -->
                  <AppText>{{ data?.Street }}</AppText>

                  <!-- Copy Icon -->
                  <AppIcon
                    :name="ICON.DOCUMENT_DUPLICATE"
                    role="button"
                    color="primary"
                    class="round-hover"
                    @click="handleCopy"
                  />
                </AppFlex>

                <!-- Localization -->
                <AppFlex direction="col" gap="1">
                  <AppText>{{ data?.Latitude }}</AppText>
                  <AppText>{{ data?.Longitude }}</AppText>
                </AppFlex>
              </AppFlex>
            </template>
          </AppCard>
        </template>
      </AppTooltip>
    </AppFlex>
  </div>
</template>
