import * as yup from "yup"

import { REGULAR_EXPRESIONS } from "@/lib/constants"
import { tc } from "@/lib/services"

export default {
  // Form Data
  data: {
    Name: "",
    Phone: "",
    Nic: "",
    Email: "",
    DocumentationStatus: false
  },

  // Form Validations
  schema: yup.object().shape({
    /* Name */
    Name: yup.string().required(tc("Validations.Required")),

    /* Phone */
    Phone: yup.string()
      .required(tc("Validations.Required"))
      .transform(value => !value ? "" : value )
      .matches(REGULAR_EXPRESIONS.SPANISH_PHONE_NUMBER, tc("Validations.InvalidPhone")),

    /* Dni */
    Nic: yup.string(),

    /* Email */
    Email: yup.string()
      .email(tc("Validations.InvalidEmail")),

    /* Documentation Status */
    DocumentationStatus: yup.boolean()
  })
}