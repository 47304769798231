<script>
export default {
  name: "AppTooltipMapPopup"
}
</script>

<script setup>
import { AppFlex } from "@/components/layouts"
import { AppText } from "@/components/ui"

defineProps({ data: Object })
</script>

<template>
  <AppFlex direction="col" gap="1">
    <AppText>
      {{ data?.Text }}
    </AppText>
    <AppText class="mt-1">
      {{ data?.CreatedAt }}
    </AppText>
  </AppFlex>
</template>
