import { getCurrentInstance } from "vue"

// A composable function to access global properties and methods from Vue app context
export const usePlugins = () => {
  const globalProperties = getCurrentInstance?.()?.appContext?.config?.globalProperties

  if (!globalProperties) return {}

  const {
    $alert,
    $axios,
    $closeAlertModal,
    $closeLoader,
    $showLoader,
    $toast
  } = globalProperties

  return {
    $alert,
    $axios,
    $closeAlertModal,
    $closeLoader,
    $showLoader,
    $toast
  }
}
