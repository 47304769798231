<script>
export default {
  name: "AppMultiSelect"
}
</script>

<script setup>
import { watch } from "vue"

import { AppCheckbox } from ".."
import { AppButton, AppDivider, AppIcon, AppSpinner, AppText } from "../.."

import { useAppMultiSelect } from "./composables/useAppMultiSelect"

import { AppFlex } from "@/components/layouts"
import { ICON } from "@/lib/constants"
import { helper } from "@/lib/services"

const props = defineProps({
  alias: {
    type: String,
    required: true
  },
  name: {
    type: String,
    default: ""
  },
  placeholder: {
    type: String,
    default: ""
  },
  modelValue: {
    type: Array
  },
  options: {
    type: Array,
    default: []
  },
  loading: {
    type: Boolean,
    default: false
  },
  displayProperty: {
    type: String,
    default: "Name"
  },
  valueProperty: {
    type: String,
    default: "Value"
  },
  manyItems: {
    type: Boolean,
    default: false
  },
  isAbsolute: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits(["update:modelValue"])

const {
  // States
  filteredOptions,
  menuIsVisible,
  selectedOptions,
  selectorPlaceholder,
  text,

  // Functions
  handleTextChange,
  handleToggleMenu,
  isSelected,
  removeItem,
  updateSelection,
  getSelectedOptionText
} = useAppMultiSelect(props, emit)

watch(() => props.options, () => {
  handleTextChange()
})
</script>

<template>
  <div
    v-click-outside="() => menuIsVisible = false"
    class="app-multiselect"
  >
    <!-- Many options here... -->
    <AppFlex
      v-if="selectedOptions?.length > 0 && manyItems"
      :wrap="true"
      gap="2"
      class="mb-2"
    >
      <AppButton
        v-for="(option, index) in selectedOptions" :key="index"
        :outline="true"
        :reverse="true"
        variant="primary"
        size="sm"
        type="button"
        :icon="ICON.X_MARK"
        :text="helper.substring(getSelectedOptionText(option), 5)"
        @on-clicked="removeItem(option)"
      />
    </AppFlex>

    <!-- Input -->
    <input
      :id="alias"
      v-model="text"
      :placeholder="selectorPlaceholder"
      :class="['form-control app-multiselect-input', { 'menu-open' : menuIsVisible, 'disabled-tag': loading }]"
      @input="handleTextChange"
      @click="handleToggleMenu"
    >

    <!-- Icon -->
    <div class="multiselect-icon">
      <AppIcon
        color="black"
        size="18"
        :name="ICON.CHEVRON_DOWN"
        :class="['text-muted ', { 'down-icon-opened': menuIsVisible }]"
      />
    </div>

    <!-- Loader -->
    <div v-if="loading" class="app-multiselect-loading">
      <AppSpinner />
    </div>

    <!-- Items List -->
    <div
      v-if="menuIsVisible"
      class="app-multi-select-menu"
      :class="{ 'position-absolute': isAbsolute }"
    >
      <!-- Empty -->
      <AppFlex
        v-if="filteredOptions?.length === 0"
        justify="center"
        align="center"
        class="bg-soft-blue py-2 rounded"
      >
        <AppText type="muted">
          {{ $t('General.NoRecords') }}
        </AppText>
      </AppFlex>

      <!-- Checkbox -->
      <div
        v-for="(option, index) in filteredOptions"
        :key="option?.[valueProperty]"
        :class="['app-multi-select-checkbox-item px-2', { 'bg-soft-blue round-hover' : isSelected(option?.[valueProperty])}]"
      >
        <AppCheckbox
          v-if="manyItems ? index < 80 : true"
          :model-value="isSelected(option?.[valueProperty])"
          :name="`${name}-${option?.[valueProperty]}`"
          :alias="`${name}-${option?.[valueProperty]}`"
          :label="`${option?.[displayProperty]}`"
          @update:modelValue="updateSelection(option?.[valueProperty])"
        />

        <AppDivider v-if="manyItems ? index < 80 : true" class="my-1" />
      </div>
    </div>
  </div>
</template>

<style
  src="./styles/app-multi-select.styles.scss"
  lang="scss"
  scoped
/>