<script>
export default {
  name: "AppLoader"
}
</script>

<script setup>
import { onMounted, onUnmounted } from "vue"

import { AppFlex } from "@/components/layouts"
import { useHtmlDocument } from "@/composables"

const { setOverFlow } = useHtmlDocument()

onMounted(() => setOverFlow("hidden"))
onUnmounted(() => setOverFlow("auto"))
</script>

<template>
  <AppFlex
    justify="center"
    align="center"
    class="app-spinner position-fixed"
  >
    <div
      class="spinner-grow spinner-grow-sm text-primary position-relative"
      role="status"
    />
    <div
      class="position-absolute spinner-border spinner-border-lg text-primary"
      role="status"
    />
  </AppFlex>
</template>

<style scoped lang="scss">
.app-spinner {
  inset: 0;
  z-index: $z-index-loader;
  background-color: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(0.3px);
  height: 100%;
  width: 100%;
}
</style>
