import * as yup from "yup"

import { tc } from "@/lib/services"

export default {
  // Form Data
  data: {
    Plate: "",
    VehicleTypeID: "",
    VehicleClassID: "",
    DocumentationStatus: false
  },

  // Form Validations
  schema: yup.object().shape({
    /* Registration */
    Plate: yup.string().required(tc("Validations.Required")),

    /* Vehicle Type */
    VehicleTypeID: yup.string().required(tc("Validations.Required")),

    /* Enviromental Label */
    VehicleClassID: yup.string().required(tc("Validations.Required")),

    /* Documentation Status */
    DocumentationStatus: yup.boolean()
  })
}