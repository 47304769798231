const _debounce = (fn, delay) => {
  let timer = null
  return function (...args) {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fn.apply(this, args)
    }, delay)
  }
}

export const debounce = (el, binding) => {
  if (binding.value !== binding.oldValue) {
    el.oninput = _debounce(() => {
      el.dispatchEvent(new Event("change"))
    }, parseInt(binding.value) || 500)
  }
}
