import { createApp } from "vue"

import { createPinia } from "pinia"

import App from "./App.vue"
import * as directives from "./directives"
import * as plugins from "./plugins"
import router from "./router"

import "./assets/styles/main.scss"

const app = createApp(App)

// Set global router
app.use(router)

// Set global Pinia Store
const pinia = createPinia()
app.use(pinia)

// I18n plugin
app.config.productionTip = false
app.use(plugins.i18n)

/* Set directives */
app.directive("debounce", (el, binding) => directives.debounce(el, binding))
app.directive("tooltip", directives.tooltipDirective)
app.directive("click-outside", directives.clickOutside)

// Set Global Local plugins
app.use(plugins.alertModalPlugin)
app.use(plugins.toastPlugin)
app.use(plugins.loaderPlugin)
app.use(plugins.axiosPlugin)


// Mount Application
app.mount("#app")
