import { ROUTE_NAMES, ROUTE_PATHS } from "@/lib/constants"

export default [
  // Login
  {
    path: ROUTE_PATHS.AUTH.LOGIN,
    name: ROUTE_NAMES.AUTH.LOGIN,
    component: () => import(/* webpackChunkName: "login" */ "./modules/login/Index.vue")
  },
  // Forgot Password
  {
    path: ROUTE_PATHS.AUTH.FORGOT_PASSWORD,
    name: ROUTE_NAMES.AUTH.FORGOT_PASSWORD,
    component: () => import(/* webpackChunkName: "forgot-password" */ "./modules/forgot-password/Index.vue")
  }
]