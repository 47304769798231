<script>
export default {
  name: "AppBorderedContainer"
}
</script>

<template>
  <div class="content">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.content {
  border-radius: 16px;
  border: 1px solid $grey-200;
  padding: 25px 30px 20px 25px;
}
</style>