<script>
export default {
  name: "AppText"
}
</script>

<script setup>
import { computed } from "vue"

import AppIcon from "../icon/AppIcon.vue"

const props = defineProps({
  size: {
    type: String,
    default: null,
    validator: value => ["1", "2", "3", "4", "5", "6"].includes(value)
  },
  type: {
    type: String,
    default: null,
    validator: value => [
      "primary", "secondary", "success", "danger", "warning",
      "info", "light", "dark", "body", "muted", "white", "black",
      "orange"
    ].includes(value)
  },
  weight: {
    type: String,
    default: null,
    validator: value => ["bold", "bolder", "normal", "light", "ligther"].includes(value)
  },
  tag: {
    type: String,
    default: "span",
    validator: value => ["p", "span", "h3", "h1", "small", "label"].includes(value)
  },
  transform: {
    type: String,
    default: null,
    validator: value => ["lowercase", "uppercase", "capitalize"].includes(value)
  },
  lh: {
    type: String,
    default: null,
    validator: value => ["1", "sm", "base", "lg"].includes(value)
  },
  icon: {
    type: String,
    default: null
  },
  iconType: {
    type: String,
    default: null
  },
  iconVariant: {
    type: String,
    default: "outline"
  },
  asteriskIcon: {
    type: Boolean,
    default: false
  },
  decoration: {
    type: String,
    default: null,
    validator: value => ["underline", "line-through", "none"].includes(value)
  }
})

const textClasses = computed(() => {
  const classes = []

  classes.push(
    // Size
    ...(props.size ? [`fs-${props.size}`] : []),

    // Type (Color)
    ...(props.type ? [`text-${props.type}`] : []),

    // Weight
    ...(props.weight ? [`fw-${props.weight}`] : []),

    // Transform
    ...(props.transform ? [`text-${props.transform}`] : []),

    // Line heigth
    ...(props.lh ? [`lh-${props.lh}`] : []),

    // Text decoration
    ...(props.decoration ? [`text-decoration-${props.decoration}`] : []),

    // Flex layout if text has icon
    ...(props.icon || props.asteriskIcon ? ["d-flex gap-2"] : [])
  )

  return classes
})
</script>

<template>
  <component
    :is="tag"
    :class="textClasses"
  >
    <!-- Icon -->
    <AppIcon
      v-if="icon"
      :name="icon"
      :color="iconType || type"
      :variant="iconVariant"
      size="18"
    />

    <!-- Content -->
    <slot />

    <!-- Asteris Icon -->
    <span v-if="asteriskIcon" class="text-orange">
      &#42;
    </span>
  </component>
</template>