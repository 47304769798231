<script>
export default {
  name: "AppHeaderNotificationCard"
}
</script>

<script setup>
import { AppEmpty } from "@/components/common"
import { AppFlex } from "@/components/layouts"
import { AppCard, AppLinkButton } from "@/components/ui"
import { NotificationItem } from "@/components/wrappers"
import { useAxios } from "@/composables"
import { ROUTE_NAMES } from "@/lib/constants"
import { useHeaderAlertStore } from "@/stores"

defineEmits(["closeCard"])
const headerAlerts = useHeaderAlertStore()
const { request } = useAxios()
</script>

<template>
  <AppCard
    :title="$t('Admin.Alerts.Title')"
    class="notifcation-card zoomin-animation"
  >
    <!-- Notification List -->
    <AppFlex direction="col" class="content">
      <!-- Empty -->
      <AppEmpty
        v-if="headerAlerts.alerts?.length === 0"
        class="mb-3 rounded-8 ps-3"
      />

      <!-- Notification Card -->
      <template v-else>
        <NotificationItem
          v-for="(notification, index) in headerAlerts?.alerts"
          :key="index"
          :notification="notification"
          :show-redirect="false"
          @handleReadAlert="headerAlerts.readAlert($event, request)"
        />
      </template>
    </AppFlex>

    <!-- Link Btn -->
    <AppLinkButton
      :to="{ name: ROUTE_NAMES.ADMIN.ALERTS }"
      :block="true"
      :text="$t('Admin.Alerts.ShowAll')"
      @click="$emit('closeCard')"
    />
  </AppCard>
</template>

<style
  src="./styles/app-header-notification-card.styles.scss"
  lang="scss"
  scoped
/>