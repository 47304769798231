<script>
export default {
  name: "AppTable"
}
</script>

<script setup>
import { inject, ref, watch } from "vue"

import { AppAlert, AppPagination, AppText } from ".."

import { AppAddressColumn, AppBodyTableEmpty, AppTableHead } from "./components"
import { useAppTable } from "./composables/useAppTable"

import { AppActions } from "@/components/common"
import { AppFlex, AppScroll } from "@/components/layouts"
import { datatableSymbol } from "@/lib/constants"

const props = defineProps({
  columns: {
    type: Array,
    required: true
  },
  data: {
    type: Array,
    required: true
  },
  clickRowToEdit: {
    type: Boolean,
    default: false
  },
  emptyText: {
    type: String,
    default: null
  },
  showResultsLabel: {
    type: Boolean,
    default: true
  },
  totalRecords: {
    type: Number,
    default: 0
  },
  filters: {
    type: Object,
    default: {}
  },
  showPagination: {
    type: Boolean,
    default: true
  }
})

defineEmits([
  "handleEdit",
  "handleDelete"
])

const columnsRef = ref(props.columns)
const injected = inject(datatableSymbol, null)

const {
  // States

  // Functions
  columnActionIsVisible,
  getRowText,
  getSecondRowText
} = useAppTable()

watch(() => props.columns, newColumns => columnsRef.value = newColumns)
</script>

<template>
  <div>
    <!-- Results texts -->
    <div v-if="showResultsLabel" class="mt-2 ms-4">
      <AppText>
        {{ $t('General.Results', { value: totalRecords }) }}
      </AppText>
    </div>

    <!-- Responsive scroll layout -->
    <AppScroll>
      <!-- Main Table -->
      <table class="app-table">
        <!-- Headers -->
        <AppTableHead
          :columns="columnsRef"
          @updateSorter="injected?.updateSorter?.($event)"
        />

        <!-- Body -->
        <tbody class="app-table-body">
          <!-- Empty Content -->
          <AppBodyTableEmpty
            v-if="totalRecords === 0"
            :colspan="columnsRef.length"
            :empty-text="emptyText"
          />

          <!-- Table Content -->
          <tr
            v-for="(row, rowIndex) in data"
            :key="rowIndex"
            class="app-body-tr"
            :class="clickRowToEdit ? 'hover-default' : ''"
            @click="clickRowToEdit ? $emit('handleEdit', row) : null"
          >
            <slot name="row" :row="row">
              <!-- Columns -->
              <td
                v-for="(column, columnIndex) in columnsRef"
                :key="columnIndex"
                :class="'app-body-td'"
              >
                <!-- Optional Action Col -->
                <template v-if="columnActionIsVisible(row, column)">
                  <slot
                    name="action-col"
                    :item="row"
                  >
                    <AppActions
                      :class="{ 'app-table-row-centered': column?.row_centered }"
                      @edit="$emit('handleEdit', row)"
                      @delete="$emit('handleDelete', row)"
                    />
                  </slot>
                </template>

                <!-- Optional Alert Column -->
                <template v-else-if="column?.variant === 'alert'">
                  <AppAlert
                    v-if="getRowText(row, column)"
                    :text="getRowText(row, column)"
                    :variant="column?.getAlertType?.(row)"
                    :show-icon="false"
                    :text-size="'12'"
                    shape="rounded-100"
                    gap="1"
                    size="sm"
                  />
                </template>

                <!-- Optional Address Column  -->
                <template v-else-if="column?.variant === 'address'">
                  <AppAddressColumn
                    :data="row"
                    :is-first="rowIndex === 0"
                  />
                </template>

                <!-- Optional Two Texts Column  -->
                <template v-else-if="column?.second_key">
                  <AppFlex direction="col" gap="1" class="app-two-text-column">
                    <AppText>{{ getRowText(row, column) }}</AppText>
                    <AppText>{{ getSecondRowText(row, column) }}</AppText>
                  </AppFlex>
                </template>

                <template v-else>
                  <!-- Detault text -->
                  <AppText>
                    {{ getRowText(row, column) }}
                  </AppText>
                </template>
              </td>
            </slot>
          </tr>
        </tbody>

      <!-- Footer -->
      </table>
    </AppScroll>

    <AppPagination
      v-if="showPagination && totalRecords > 0"
      :current-page="filters?.page || 1"
      :selected-per-page="filters?.Limit || 5"
      :total-records="totalRecords"
      :total-pages="Math.ceil(+totalRecords / +filters?.Limit) || 0"
      @updatePerPage="injected?.changePerPage?.($event)"
      @updatePage="injected?.paginate?.($event)"
    />
  </div>
</template>

<style
  src="./styles/app-table.styles.scss"
  lang="scss"
  scoped
/>