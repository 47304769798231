<script>
export default {
  name: "AppButtonGroup"
}
</script>

<script setup>
import { AppIcon, AppText } from ".."

import { AppFlex, AppScroll } from "@/components/layouts"

const props = defineProps({
  buttons: Array,
  current: [String, Number],
  full: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(["updateActive"])

const handleClick = button => {
  const value = props?.full ? button : button?.key
  emit("updateActive", value)
}
</script>

<template>
  <div>
    <AppScroll>
      <div class="btn-group mx-1" role="group">
        <button
          v-for="(button, index) in buttons"
          :key="index"
          type="button"
          :class="[
            'btn px-3 py-2',
            'round-hover-default',
            current !== button?.key ? 'btn-outline-link primary border-soft-brown text-gray' : 'btn-primary',
          ]"
          @click="() => handleClick(button)"
        >
          <!-- Content -->
          <AppFlex gap="2" align="center">
            <!-- Icon -->
            <AppIcon
              v-if="button?.icon"
              variant="solid"
              size="19.5"
              :color="current === button?.key ? 'white' : 'muted'"
              :name="button?.icon"
            />

            <!-- Text -->
            <AppText :type="current === button?.key ? 'white' : 'muted'">
              {{ button?.text }}
            </AppText>
          </AppFlex>
        </button>
      </div>
    </AppScroll>
  </div>
</template>
