import * as yup from "yup"

import { REGULAR_EXPRESIONS } from "@/lib/constants"
import { tc } from "@/lib/services"

export default {
  data: {
    Name: "",
    TaxIdNumber: "",
    EmailBilling: "",
    EmailOperations: "",
    PhoneContact: "",
    PhoneEmergency: "",
    DocumentationStatus: false
  },

  schema: yup.object().shape({
    /* Name */
    Name: yup.string().required(tc("Validations.Required")),

    /* Dni */
    TaxIdNumber: yup.string(),

    /* Email Billing */
    EmailBilling: yup.string()
      .email(tc("Validations.InvalidEmail"))
      .required(tc("Validations.Required")),

    /* Email Operations */
    EmailOperations: yup.string()
      .email(tc("Validations.InvalidEmail"))
      .required(tc("Validations.Required")),

    /* Phone Contact */
    PhoneContact: yup.string()
      .required(tc("Validations.Required"))
      .transform(value => !value ? "" : value )
      .matches(REGULAR_EXPRESIONS.SPANISH_PHONE_NUMBER, tc("Validations.InvalidPhone")),

    /* Phone Emergency */
    PhoneEmergency: yup.string()
      .transform(value => !value ? "" : value )
      .matches(REGULAR_EXPRESIONS.SPANISH_PHONE_NUMBER, tc("Validations.InvalidPhone")),

    /* Documentation Status */
    DocumentationStatus: yup.boolean()
  })
}
