<script>
export default {
  name: "AppModal"
}
</script>

<script setup>
import { onMounted, onUnmounted, watch } from "vue"

import { AppButton, AppText } from ".."

import { AppFlex } from "@/components/layouts"
import { useHtmlDocument } from "@/composables"

const props = defineProps({
  visible: Boolean,
  title: String,
  size: {
    type: String,
    default: "lg",
    validator: value => ["xxl", "xl", "lg", "md", "sm"].includes(value)
  },
  subtitle: {
    type: String,
    default: null
  },
  centered: {
    type: Boolean,
    default: true
  },
  loading: {
    type: Boolean,
    default: false
  },
  cancelText: {
    type: String,
    default: null
  },
  acceptText: {
    type: String,
    default: null
  },
  footerVisible: {
    type: Boolean,
    default: true
  },
  resetIsVisible: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: "normal",
    validator: value => ["normal", "form"].includes(value)
  }
})
defineEmits([
  "handleClose",
  "handleAccept",
  "handleReset"
])

const { setOverFlow } = useHtmlDocument()

watch(
  () => props?.visible,
  visible => setOverFlow(visible ? "hidden" : "auto")
)

onMounted(() => setOverFlow(props.visible ? "hidden" : "auto"))
onUnmounted(() => setOverFlow("auto"))
</script>

<template>
  <Teleport
    v-if="visible"
    to="body"
  >
    <!-- Mask -->
    <div
      v-if="visible"
      :class="'modal modal-mask show fade-in'"
      aria-modal="true"
      role="dialog"
      tabindex="-1"
    >
      <!-- Modal -->
      <div
        :class="`modal-dialog modal-${size} ${centered && 'modal-dialog-centered'}`"
      >
        <div :class="'modal-content animate-up'">
          <!-- Full content -->
          <slot name="content">
            <!-- Header -->
            <div class="modal-header">
              <slot name="title">
                <AppFlex direction="col" gap="2">
                  <AppText class="default-title">
                    {{ title }}
                  </AppText>

                  <AppText v-if="subtitle">
                    {{ subtitle }}
                  </AppText>
                </AppFlex>
              </slot>
            </div>

            <!-- Content -->
            <div
              :class="['app-modal-body modal-body-overflow', {
                'p-3': type === 'normal',
                'px-3': type === 'form'
              }]"
            >
              <slot />
            </div>

            <!-- Footer -->
            <slot name="footer">
              <AppFlex v-if="footerVisible" justify="between" class="mt-2 p-3" xsdir="col">
                <AppFlex justify="start" gap="4">
                  <!-- Accept -->
                  <AppButton
                    id="app-accept-modal-btn"
                    size="xl"
                    :text="acceptText || $t('General.Accept')"
                    @on-clicked="$emit('handleAccept')"
                  />

                  <!-- Cancel -->
                  <AppButton
                    id="app-close-modal-btn"
                    variant="primary"
                    :outline="true"
                    size="xl"
                    :text="cancelText || $t('General.Cancel')"
                    @on-clicked="$emit('handleClose')"
                  />
                </AppFlex>

                <!-- Reset -->
                <AppButton
                  v-if="resetIsVisible"
                  id="app-reset-modal-btn"
                  variant="primary"
                  :outline="true"
                  size="xl"
                  :text="$t('General.ResetFilters')"
                  @on-clicked="$emit('handleReset')"
                />
              </AppFlex>
            </slot>
          </slot>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style
  lang="scss"
  src="./styles/app-modal.styles.scss"
  scoped
/>
