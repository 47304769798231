import { tc } from "./i18n.service"

class HelperService {
  // Copy text to clipboard
  copy(text) {
    navigator.clipboard.writeText(text)
  }

  // Generate random lorem text
  generateLoremIpsum(length) {
    const loremIpsum = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."

    let result = ""
    while (result.length < length) {
      result += loremIpsum + " "
    }

    return result.substring(0, length)
  }

  // Capitalize text
  capitalize(text) {
    if (!text) return ""
    return text?.toLowerCase().split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
  }

  // Substring
  substring(text, quantity) {
    if (!text) return ""
    if (text.length <= quantity) return text
    return text?.substring?.(0, quantity)
  }

  // Function to get a price formatted
  getFormattedPrice(price, { locale = "es", currencyCode = "EUR" } = {}) {
    if (!price) return null
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currencyCode,
      currencyDisplay: "narrowSymbol"
    })?.format(price)
  }

  // Is null
  isNullFalsy(value) {
    return [null, undefined].includes(value)
  }

  getHighlightedText(label, text) {
    if (!label || !text) return ""
    return label?.replace(
      new RegExp(text?.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "gi"),
      "<span class='text-primary'>$&</span>"
    )
  }

  // Get Minutes Selector
  getMinutesSelector({ allowNever = false, lowValues = false } = {}) {
    let minutes = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60]

    if (allowNever) {
      minutes = [
        { Value: -1, Name: tc("General.Never") },
        ...minutes
      ]
    }

    if (lowValues) {
      minutes = [1, 2, 3, 4, ...minutes]
    }

    return minutes.map(Value => {
      if (Value?.Name) return Value
      const i18nKey = Value === 1 ? "Minute" : "Minutes"
      return { Value, Name: tc(`General.${i18nKey}`, { Value }) }
    })
  }

  getPercentageSelector() {
    return [5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map(Value => ({
      Value, Name: tc("General.Percentage", { Value })
    }))
  }
}

const helper = new HelperService()
export default helper
