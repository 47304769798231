<script>
export default {
  name: "AppCheckboxMenu"
}
</script>

<script setup>
defineProps({ checked: Boolean })
defineEmits(["on-change"])
</script>

<template>
  <!-- Checkbox -->
  <input
    id="toggleChecker"
    type="checkbox"
    :checked="checked"
    @change="$emit('on-change', $event)"
  >

  <!-- Label menu -->
  <label
    id="togglerLable"
    for="toggleChecker"
  >
    <div class="checkboxtoggler">
      <!-- Lines -->
      <div
        v-for="item in [1,2,3]"
        :key="item"
        :class="`line-${item}`"
      />
    </div>
  </label>
</template>

<style lang="scss" scoped src="./styles/app-checkbox-menu.styles.scss" />