<script>
export default {
  name: "AppBadge"
}
</script>

<script setup>
import { computed } from "vue"

import { AppIcon } from ".."

const props = defineProps({
  type: {
    type: String,
    default: "primary",
    validator: value => ["primary", "secondary", "success", "danger", "orange", "pink", "warning"].includes(value)
  },
  text: {
    type: [String, Number],
    required: true
  },
  shape: {
    type: String,
    default: null,
    validator: value => ["circle", "pill"].includes(value)
  },
  uppercase: {
    type: Boolean,
    default: false
  },
  icon: {
    type: String,
    default: null
  },
  weight: {
    type: String,
    default: "bold",
    validator: value => ["bold", "bolder", "normal", "light", "ligther"].includes(value)
  }
})

const badgeClass = computed(() => {
  const classes = ["badge", "align-self-center"]

  classes.push(
    // Type
    ...([`bg-${props.type}`]),

    // Rounded
    ...(props.shape === "pill" ? ["rounded-pill"] : []),

    // Circle
    ...(props.shape === "circle" ? ["rounded-circle"] : []),

    // Black Texts
    ...(["warning", "success", "pink"].includes(props.type) ? ["text-black"] : []),

    // Uppercase Text
    ...(props.uppercase ? ["text-uppercase"] : []),

    // Weight
    ...(props.weight ? [`fw-${props.weight}`] : []),
  )

  return classes
})
</script>

<template>
  <span :class="badgeClass">
    <!-- Icon -->
    <AppIcon
      v-if="icon"
      :name="icon"
      color="black"
      size="14"
      class="mb-1 me-1"
    />

    <!-- Text -->
    {{ text }}
  </span>
</template>
