<script>
export default {
  name: "AppSearchFilter"
}
</script>

<script setup>
import { ref } from "vue"

import { AppIcon } from "../.."
import AppField from "../field/AppField.vue"

import { ICON } from "@/lib/constants"

const props = defineProps({
  modelValue: {
    type: String,
    default: ""
  },
  debounce: {
    type: Number,
    default: 500
  },
  label: {
    type: String,
    default: null
  },
  placeholder: {
    type: String,
    default: null
  },
  displayLabel: {
    type: Boolean,
    default: true
  },
  showIcon: {
    type: Boolean,
    default: true
  },
  type: {
    type: String,
    default: "text",
    validator: value => ["text", "number"].includes(value)
  }
})
const emit = defineEmits(["update:modelValue"])

const lastSearch = ref(props.modelValue)

const updateSearch = e => {
  if (e.target.value !== lastSearch.value) {
    lastSearch.value = e.target.value
    emit("update:modelValue", lastSearch.value)
  }
}

</script>

<template>
  <!-- Label -->
  <AppField
    alias="search"
    :label="displayLabel ? label || $t('General.Searcher') : ''"
  >
    <div class="input-group">
      <!-- Icon -->
      <span v-if="showIcon" class="input-group-text">
        <AppIcon
          :name="ICON.MAGNIFYING_GLASS"
          color="primary"
          size="20"
        />
      </span>

      <!-- Input -->
      <input
        id="search"
        v-debounce="debounce"
        :value="modelValue"
        :placeholder="placeholder || $t('General.Search')"
        :autocomplete="'off'"
        :type="type"
        class="form-control input-shadow"
        :class="{ 'input-border-left-none': showIcon }"
        @change="updateSearch"
      >
    </div>
  </AppField>
</template>
