<script>
export default {
  name: "AppSwitch"
}
</script>

<script setup>
import { ref, watch } from "vue"

import AppField from "../field/AppField.vue"

import { useAppSwtich } from "./composables/useAppSwtich"

const props = defineProps({
  label: {
    type: String,
    default: ""
  },
  alias: {
    type: String,
    default: ""
  },
  modelValue: {
    type: Boolean,
    default: false
  },
  muted: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    default: "sm",
    validator: value => ["sm", "lg"].includes(value)
  },
  variant: {
    type: String,
    default: "default",
    validator: value => ["default", "card"].includes(value)
  }
})

defineEmits(["update:modelValue"])

const value = ref(props?.modelValue)

const { swtichClasses, containerClasses } = useAppSwtich(props)

watch(() => props.modelValue, newValue => {
  value.value = newValue
})
</script>

<template>
  <AppField
    :alias="alias"
    :label="label"
    :class="containerClasses"
  >
    <template #label>
      <slot />
    </template>

    <div class="form-check form-switch">
      <input
        :id="alias"
        v-model="value"
        :class="swtichClasses"
        type="checkbox"
        role="switch"
        @change="(event) => $emit('update:modelValue', event?.target?.checked)"
      >
    </div>
  </AppField>
</template>

<style
  src="./styles/app-switch.styles.scss"
  lang="scss"
  scoped
/>