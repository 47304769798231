<script>
export default {
  name: "AppAutoComplete",
  inheritAttrs: true
}
</script>

<script setup>
import { AppSpinner, AppText } from "../.."
import AppField from "../field/AppField.vue"

import { useAppAutoComplete } from "./composables/useAppAutoComplete"

const props = defineProps({
  alias: {
    type: String,
    required: true
  },
  name: {
    type: String,
    required: true
  },
  label: {
    type: String,
    default: null
  },
  placeholder: {
    type: String,
    default: null
  },
  disabled: {
    type: Boolean,
    default: false
  },
  url: {
    type: String,
    required: true
  },
  responseKey: {
    type: String,
    required: true
  },
  paramKey: {
    type: String,
    default: "Text"
  },
  valueProperty: {
    type: String,
    default: "Value"
  },
  displayProperty: {
    type: String,
    default: "Name"
  },
  error: {
    type: String,
    default: null
  },
  isAbsolute: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits(["update:modelValue"])

const {
  // States
  customPlaceholder,
  loading,
  options,
  searchText,
  selectedItem,

  // Functions
  handleInputChange,
  handleInputClick,
  onItemClicked
} = useAppAutoComplete(props, emit)
</script>

<template>
  <div
    v-click-outside="() => options = []"
  >
    <AppField
      :alias="alias"
      :label="label"
      class="position-relative"
    >
      <!-- Text Input -->
      <input
        :id="alias"
        v-model="searchText"
        :name="name"
        :disabled="disabled"
        :placeholder="customPlaceholder"
        type="text"
        :class="'form-control'"
        @click="handleInputClick"
        @input="handleInputChange($event.target.value)"
      >

      <!-- Loader -->
      <div v-if="loading" class="app-input-loading">
        <AppSpinner />
      </div>

      <!-- Options -->
      <div
        v-if="options?.length > 0"
        class="fade-in app-dropdown-items"
        :class="{ 'position-absolute': isAbsolute }"
      >
        <!-- All Options -->
        <span
          v-for="(option, index) in options"
          :key="index"
          :class="['dropdown-item', { 'active-item': option?.[valueProperty] === selectedItem }]"
          @click="onItemClicked(option)"
        >
          {{ option?.[displayProperty] }}
        </span>
      </div>

      <!-- Error Text -->
      <AppText
        v-if="error"
        type="danger"
        tag="small"
        lh="sm"
      >
        {{ error }}
      </AppText>
    </AppField>
  </div>
</template>

<style
  src="./styles/app-auto-complete.styles.scss"
  lang="scss"
  scoped
/>