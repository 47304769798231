import { createApp, ref } from "vue"

import { AppConfirmModal } from "@/components/common"

// Define an install function that will be used to install the confirm modal into a Vue app
const install = app => {
  let container
  let confirmApp
  const confirmProps = ref({})

  // Define a function to display the confirmation dialog
  const alert = params => {

    // Set up initial properties for the confirmation dialog
    confirmProps.value = { ...params }
    confirmProps.value.visible = false

    // Create a container element for the modal and append it to the body
    container = document.createElement("div")
    container.setAttribute("id", "ConfirmModalPlug")
    document.body.appendChild(container)

    // Create a Vue app instance for the confirmation modal
    confirmApp = createApp(AppConfirmModal, {
      ...confirmProps.value,
      visible: true,
      close: () => {
        if (params && typeof params?.onClose === "function") {
          params.onClose()
        }
        close()
      },
      confirm: async () => {
        try {
          // Execute the confirmation handler function
          await params.onConfirm()
          close()

        } catch (error) {
          console.error("Error in the request:", error)
        }
      }
    })

    // Mount the modal app instance to the container
    confirmApp.mount(container)
  }

  // Define a function to close the confirmation dialog
  const close = () => {
    if (confirmApp) {
      confirmApp.unmount(container)
      container.parentNode.removeChild(container)
    }
  }

  // Add the confirm and closeConfirmModal functions to the global properties of the Vue app
  app.config.globalProperties.$alert = alert
  app.config.globalProperties.$closeAlertModal = close
}

// Export the install function as the default export of the module
export default install
