class DatesService {
  // Function to calculate the difference in days between today and a given date
  daysUntil(date) {
    // Convert the date string to a Date object
    const targetDate = new Date(date)

    // Get today's date
    const today = new Date()

    // Calculate the difference in milliseconds between the two dates
    const timeDifference = targetDate.getTime() - today.getTime()

    // Convert the time difference from milliseconds to days
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24))

    return daysDifference
  }

  // Function to get a date in dd-mm-aaaa
  todayString() {
    const today = new Date()
    const formattedDate = today.toLocaleDateString("es-ES").replace(/\//g, "-")

    return formattedDate
  }
}

const dates = new DatesService()
export default dates
