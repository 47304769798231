<script>
export default {
  name: "AppIcon"
}
</script>

<script setup>
import { computed } from "vue"

import * as HeroiconsOutline from "@heroicons/vue/24/outline"
import * as HeroiconsSolid from "@heroicons/vue/24/solid"

const props = defineProps({
  name: String,
  variant: {
    type: String,
    default: "outline"
  },
  size: {
    type: String,
    default: "28"
  },
  color: {
    type: String,
    default: "white",
    validator: value => [
      "primary", "secondary", "success", "danger", "warning",
      "info", "light", "dark", "body", "muted", "white", "black",
      "grey-500"
    ].includes(value)
  }
})

const iconMap = {
  outline: HeroiconsOutline,
  solid: HeroiconsSolid
}

const icon = computed(() => {
  const resolvedIcon = iconMap[props.variant]?.[props.name]
  return resolvedIcon || null
})

const computedClasses = computed(() => {
  const classes = []

  classes.push(
    // Color
    ...(props.color ? [`text-${props.color}`] : []),
  )
  return classes
})
</script>

<template>
  <component
    :is="icon"
    :class="computedClasses"
    :style="{ width: `${size}px`, height: `${size}px` }"
  />
</template>